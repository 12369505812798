module.exports = {
    styleBruxelles: cv => {

        const { modelBaseColor = "#247BA0", modelTitleColor, lng } = cv.Metadata
        
        return `
            /* ----------------------------------------------- DOCUMENT */
            .header{
                padding-top: 40px;
                padding-bottom: 10px;
                border-left: 20px solid ${modelBaseColor};
            }
            .body{
                padding-left: 40px;
                border-left: 20px solid ${modelBaseColor};
                margin-top: -1px;
                padding-right: 40px;
            }
            .container{
                display: table;
                padding-top: 5px;
            }
            .content{
                margin-bottom: 5px;
                min-width: 490px;
                max-width: 490px;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                display: table-cell;
                width: 170px;
                padding-top: 5px;
                ${lng === "ar" ? "padding-left: 10px" : "padding-right: 10px"}; 
                vertical-align: top;
            }
            .section-title-content{
                border: 2px solid ${modelTitleColor || modelBaseColor};
            }
            .section-title{
                color: white;
                background-color: ${modelTitleColor || modelBaseColor};
                border: 2px solid white;
                padding: 3px
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                ${lng === "ar" ? "padding-right: 20px" : "padding-left: 20px"}; 
            }
            .profile-pic {
                border: 3px solid white;
                box-shadow:  0 0 0 3px ${modelBaseColor};
            }
            /* ----------------------------------------------- CONTAC INFO */
            .contact-info-tag-icon{
                color: black;
            }
            /* ----------------------------------------------- PORTFOLIO */
            .portfolio-item {
                height: 160px;
                width: 160px;
                margin-right: 0;
            }
            /* ----------------------------------------------- ATTACHMENT */
            .attachment-item {
                height: 115px;
                width: 115px;
            }	
            /* ----------------------------------------------- HOBBY */
            .hobby-item {
                display: inline-table;
                width: 32%;
            }
            .hobby-icon-container{
                width: 30%;
            }
        `

    }
}