module.exports = {
    styleMilan: cv => {

        const { modelBaseColor = "#247BA0", lng } = cv.Metadata

        return `
            /* ----------------------------------------------- DOCUMENT */
            .header{
                padding: 40px;
                background-color: ${modelBaseColor};
            }
            .body{
                padding: 15px 40px 0px 40px;
            }
            .container{
                display: table;
            }
            .content{
                min-width: 490px;
                max-width: 490px;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                display: table-cell;
                width: 170px;
                text-align: right;
                padding-top: 5px;
                padding-right: 20px;
                vertical-align: top;
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                background-color: transparent;
                ${lng === "ar" ? "padding-left: 30px" : "padding-right: 30px"}; 
            }
            .profile-pic {
                width: 120px;
                height: 170px;
                border: 3px solid white;
                border-radius: 0px !important;
            }
            /* ----------------------------------------------- NAME */
            .name-container{
                text-shadow: 0px 0px 5px black;
                background-color: transparent;
            }
            .name, .surname {
                color: white;
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);
            }
            .surname{
                padding-top: 0px;
                font-weight: bold;
            }
            .professional-title{
                color: white;
                text-shadow: 0px 0px 5px black;
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);	
            }
            /* ----------------------------------------------- CONTAC INFO */
            .contact-info-tag-icon{
                color: black;
            }
            /* ----------------------------------------------- PORTFOLIO */
            .portfolio-item {
                height: 160px;
                width: 160px;
                margin-right: 0;
            }
            /* ----------------------------------------------- ATTACHMENT */
            .attachment-item {
                height: 115px;
                width: 115px;
            }
            /* ----------------------------------------------- HOBBY */
            .hobby-item {
                display: inline-table;
                width: 32%;
            }
            .hobby-icon-container{
                width: 30%;
            }
            `

    }
}