import React, { useCallback, useEffect, useState } from "react";

import { t } from "cvpop-localization-sdk";

import WebAlert from "../components/atoms/WebAlert";


export const AlertContext = React.createContext({
    alert: async () => { },
    confirm: async () => { },
    confirmDelete: async () => { },
    prompt: async () => { }
});


export const AlertContextProvider = ({ children }) => {

    // ------------------------------------------------------------------------------------------------------------------------
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [alertActionText, setAlertActionText] = useState("OK");
    const [alertCancelText, setAlertCancelText] = useState("");
    const [alertActionStyle, setAlertActionStyle] = useState("");

    const [alertPrompt, setAlertPrompt] = useState(false)
    const [alertPromptDefaultValue, setAlertPromptDefaultValue] = useState("");

    const [alertIsOpen, setAlertIsOpen] = useState(false);

    const [rejectPromise, setRejectPromise] = useState(null);
    const [resolvePromise, setResolvePromise] = useState(null);

    // ------------------------------------------------------------------------------------------------------------------------
    const alert = useCallback((title, message, actionText = "OK") => new Promise(res => {

        setResolvePromise(() => res);

        if (window.webkit?.messageHandlers["showAlert"])
            return window.webkit?.messageHandlers["showAlert"].postMessage(JSON.stringify({ title, message, actionText }))

        setAlertTitle(title);
        setAlertMessage(message);
        setAlertActionText(actionText)
        setAlertCancelText("")
        setAlertActionStyle("")

        setAlertIsOpen(true);

        
    }), [])

    const confirm = useCallback((title, message, actionText = "OK", cancelText = "", actionStyle) => new Promise((res, rej) => {

        setResolvePromise(() => res);
        setRejectPromise(() => rej);

        const pmJson = { title, message, actionText, cancelText: cancelText || t("cancel") }
        if (window.webkit?.messageHandlers["showConfirm"])
            return window.webkit?.messageHandlers["showConfirm"].postMessage(JSON.stringify(pmJson))

        setAlertTitle(title);
        setAlertMessage(message);
        setAlertActionText(actionText || "OK")
        setAlertCancelText(cancelText || t("cancel"))
        setAlertActionStyle(actionStyle)

        setAlertIsOpen(true);
    }), [])

    const confirmDelete = useCallback((title, message, actionText, cancelText, actionStyle) => new Promise((res, rej) => {

        setResolvePromise(() => res);
        setRejectPromise(() => rej);

        const pmJson = { title: title || t('areYouSure'), message: message || t('genericDeleteMsg'), actionText: actionText || t('delete'), cancelText: cancelText || t("cancel") }
        if (window.webkit?.messageHandlers["showConfirm"])
            return window.webkit?.messageHandlers["showConfirm"].postMessage(JSON.stringify(pmJson))

        setAlertTitle(title || t('areYouSure'));
        setAlertMessage(message || t('genericDeleteMsg'));
        setAlertActionText(actionText || t('delete'))
        setAlertCancelText(cancelText || t("cancel"))
        setAlertActionStyle(actionStyle || "danger")

        setAlertIsOpen(true);
    }), [])

    const prompt = useCallback((title, message, actionText = "OK", cancelText = "", actionStyle, defaultValue = "") => new Promise((res, rej) => {

        setResolvePromise(() => res);
        setRejectPromise(() => rej);

        const pmJson = { title, message, actionText, defaultValue, cancelText: cancelText || t("cancel") }
        if (window.webkit?.messageHandlers["showPrompt"])
            return window.webkit?.messageHandlers["showPrompt"].postMessage(JSON.stringify(pmJson))

        setAlertTitle(title);
        setAlertMessage(message);
        setAlertActionText(actionText || "OK")
        setAlertCancelText(cancelText || t("cancel"))
        setAlertActionStyle(actionStyle)

        setAlertPrompt(true);
        setAlertPromptDefaultValue(defaultValue)

        setAlertIsOpen(true);
    }), [])

    const onCancelPress = useCallback(() => {
        setAlertIsOpen(false);
        setAlertPrompt(false)
        if (rejectPromise) rejectPromise({ code: "global/cancel-action" });
        setResolvePromise(null)
        setRejectPromise(null)
        setAlertPromptDefaultValue("")
    }, [rejectPromise]);

    const onActionPress = useCallback(txt => {
        setAlertIsOpen(false);
        setAlertPrompt(false)
        if (resolvePromise) resolvePromise(txt);
        setResolvePromise(null)
        setRejectPromise(null)
        setAlertPromptDefaultValue("")
    }, [resolvePromise]);

    // ------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        window.addEventListener('onShowAlert', onActionPress)
        window.addEventListener('onShowConfirmAction', onActionPress)
        window.addEventListener('onShowConfirmCancel', onCancelPress)
        window.addEventListener('onShowPromptAction', ({ detail }) => onActionPress(detail.data))
        window.addEventListener('onShowPromptCancel', onCancelPress)
        return () => {
            window.removeEventListener('onShowAlert', onActionPress)
            window.removeEventListener('onShowConfirmAction', onActionPress)
            window.removeEventListener('onShowConfirmCancel', onCancelPress)
            window.removeEventListener('onShowPromptAction', onActionPress)
            window.removeEventListener('onShowPromptCancel', onCancelPress)
        }
    }, [onActionPress, onCancelPress])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <AlertContext.Provider value={{ alert, confirm, confirmDelete, prompt }}>
            {children}
            <WebAlert
                isOpen={alertIsOpen}

                prompt={alertPrompt}
                promptDefaultValue={alertPromptDefaultValue}

                title={alertTitle}
                message={alertMessage}
                actionText={alertActionText}
                cancelText={alertCancelText}

                actionStyle={alertActionStyle}

                onCancelPress={() => onCancelPress()}
                onActionPress={txt => onActionPress(txt)}
            />
        </AlertContext.Provider>
    );
};