module.exports = {
    styleDublin: cv => {

        const { modelBaseColor = "#247BA0", modelTitleColor, lng } = cv.Metadata

        return `
            /* ----------------------------------------------- DOCUMENT */
            .header {
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                background-color: ${modelBaseColor};
                padding: 40px;
                display: flex;
                position: relative;
                align-items: center;
            }
            .header::before {
                content: "";
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                background-color: rgba(0,0,0,0.3);
                z-index: 1;
            }
            .body {
                display: block !important;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                margin-bottom: 5px;
            }
            .section-title{
                border: 3px solid ${modelTitleColor || modelBaseColor};
                background-color: ${modelTitleColor || modelBaseColor};
                color: white;
                padding: ${lng === "ar" ? "5px 5px 5px 10px" : "5px 10px 5px 5px"}; 
                border-radius: 20px;
                ${lng === "ar" ? "border-top-right-radius: 0px" : "border-top-left-radius: 0px"}; 
                ${lng === "ar" ? "border-bottom-right-radius: 0px" : "border-bottom-left-radius: 0px"}; 
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                text-align: center;
                background-color: transparent;
                ${lng === "ar" ? "padding-left: 20px" : "padding-right: 20px"}; 
                z-index: 2;
            }
            .profile-pic {
                border: 3px solid white;
                width: 140px;
                height: 140px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                border-radius: 75px;
                margin: 0px auto;
                display: inline-block;
            }
            /* ----------------------------------------------- NAME */
            .name-container{
                background-color: transparent;
                align-self: center;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                z-index: 2;
            }
            .name, .surname, .professional-title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: white;
                font-weight: bold;
                background-color: transparent;
                text-shadow: 0px 0px 5px black;
                -webkit-print-color-adjust: exact;
                -webkit-filter: opacity(1);
            }
            /* ----------------------------------------------- SECTION PERIOD */
            .section-period-container{
                text-align: right;
            }
            /* ----------------------------------------------- PORTFOLIO */
            .unique-column > * .portfolio-item{
                height: 105px !important;
                width: 105px !important;
            }
            /* ----------------------------------------------- ATTACHMENT */
            .unique-column > * .attachment-item{
                height: 165px !important;
                width: 165px !important;
            }
        `

    }
}