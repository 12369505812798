import { useMemo, useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Button, Spinner, Link, Divider } from "@nextui-org/react"
import { produce } from "immer"

import { t } from "cvpop-localization-sdk";
import { setOffering, setUser } from 'cvpop-redux-sdk';
import { icons } from 'cvpop-assets-sdk';
import { currencies } from "cvpop-collections-sdk"
import { urls } from "cvpop-constants-sdk";

import { auth } from '../../controllers/firebase';
import { errorHandler, openUrl } from '../../controllers/app';
import { AlertContext } from '../../contexts/AlertContext';

import WebIcon from '../atoms/WebIcon';


const WebPaywall = ({ onboarding }) => {

    // ------------------------------------------------------------------------------------------------------
    const { user, userPro } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()
    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const d = useDispatch()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------------------------
    const { terms, privacy } = urls.legal

    // ------------------------------------------------------------------------------------------------------------------------
    const [producSelected, setProductSelected] = useState("com.curriculify.premium.threemonths")
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------------------------
    const featList = useMemo(() => ["MultipleCv", "AiEditor", "Translate", "NoAds", "Templates", "UnlockAll"], [])

    // ------------------------------------------------------------------------------------------------------
    const purchaseProduct = useCallback(() => {
        setLoading(true)
        if (platform === "macos" || platform === "visionos")
            window.webkit.messageHandlers["purchaseProduct"].postMessage(producSelected);

    }, [producSelected])

    const purchaseRestore = useCallback(() => {
        setLoading(true)
        if (platform === "macos" || platform === "visionos")
            window.webkit.messageHandlers["purchaseRestore"].postMessage("");
    }, [])

    const onGetOffering = useCallback(({ detail }) => d(setOffering(JSON.parse(detail.data))), [])
    const onUserCancel = useCallback(() => setLoading(false), [])

    const onPurchaseSuccess = useCallback(() => {
        d(setUser({ user: produce(user, d => { d.plan = "SUBSCRIBED"; d.planSubscriptionState = "INITIAL_PURCHASE"; }) }))
        onboarding ? n(`/app/${platform}/onboarding/completition`) : n(-1)
        setLoading(false)
    }, [user, onboarding, d, n])

    const onRestoreSuccess = useCallback(() => {
        d(setUser({ user: produce(user, d => { d.plan = "SUBSCRIBED"; d.planSubscriptionState = "RESTORED_PURCHASE"; }) }))
        onboarding ? n(`/app/${platform}/onboarding/completition`) : n(-1)
        setLoading(false)
    }, [user, onboarding, d, n])

    const onPurchaseError = useCallback(({ detail }) => {
        errorHandler(alert, "err_purchase", { message: detail })
        setLoading(false)
    }, [])
    const onRestoreError = useCallback(({ detail }) => {
        errorHandler(alert, "err_restore", { message: detail })
        setLoading(false)
    }, [])
    const onRestoreNoSubscription = useCallback(({ detail }) => {
        alert(t("errTitle"), t("purchaseNotFoundMsg"))
        setLoading(false)
    }, [])

    // ------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (userPro) onboarding ? n(`/app/${platform}/onboarding/completition`) : n(-1)
    }, [userPro, onboarding, n])

    useEffect(() => {
        if (offering) return

        if (platform === "macos" || platform === "visionos")
            window.webkit.messageHandlers["getOffering"].postMessage(fbuser.uid);

    }, [offering])


    useEffect(() => {
        window.addEventListener('onGetOffering', onGetOffering)
        window.addEventListener('onUserCancel', onUserCancel)
        window.addEventListener('onPurchaseError', onPurchaseError)
        window.addEventListener('onPurchaseSuccess', onPurchaseSuccess)
        window.addEventListener('onRestoreError', onRestoreError)
        window.addEventListener('onRestoreSuccess', onRestoreSuccess)
        window.addEventListener('onRestoreNoSubscription', onRestoreNoSubscription)

        return () => {
            window.removeEventListener('onGetOffering', onGetOffering)
            window.removeEventListener('onUserCancel', onUserCancel)
            window.removeEventListener('onPurchaseError', onPurchaseError)
            window.removeEventListener('onPurchaseSuccess', onPurchaseSuccess)
            window.removeEventListener('onRestoreError', onRestoreError)
            window.removeEventListener('onRestoreSuccess', onRestoreSuccess)
            window.removeEventListener('onRestoreNoSubscription', onRestoreNoSubscription)
        }
    }, [])


    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='min-h-screen flex justify-center items-center flex-col'>
            <Card className='max-w-md m-3'>

                <Button isIconOnly variant={"light"} className='m-3' onClick={() => n(-1)}>
                    <WebIcon name={"west"} />
                </Button>
                <CardBody>

                    {
                        !offering ? (
                            <div className='w-96 h-96 flex items-center justify-center'>
                                <Spinner />
                            </div>
                        ) : (

                            <div>
                                <h1 className='text-3xl m-4 mt-0 font-bold text-center'>
                                    {t(`appPremium`)}
                                </h1>
                                <p className="text-center mt-4">{t(`appPremiumMsg`)}</p>

                                <div className='grid grid-cols-3 gap-4 mt-4'>
                                    {
                                        featList.map(e => (
                                            <div key={`feat${e}`} className='flex flex-col items-center justify-center'>
                                                <img alt={t(`feat${e}`)} src={icons[`feature${e.toLowerCase()}`]} className='max-w-16' />
                                                <p className='mt-4 text-center text-sm'>{t(`feat${e}`)}</p>
                                            </div>
                                        ))
                                    }

                                </div>

                                <div>
                                    <div className='grid grid-cols-2 gap-4 mt-4'>
                                        {
                                            (offering || []).filter(e => e.type === "standard" && e.id !== "com.curriculify.premium.oneweek").map(p => (

                                                <div
                                                    onClick={() => setProductSelected(p.id)}
                                                    radius="lg"
                                                    className={`border-4 border-${p.id === producSelected ? "primary" : "default"} rounded-xl p-2 flex flex-col items-center justify-center text-center`}
                                                >

                                                    {
                                                        p.id === "com.curriculify.premium.threemonths" && (
                                                            <p className="px-2 py-1 bg-primary rounded-xl text-white mb-3" style={{ marginTop: -25 }}>
                                                                {t("saveMoney")} {parseInt((1 - (p.price / 3) / (offering.find(e => e.id === "com.curriculify.premium.onemonth").price)) * 100)}%
                                                            </p>
                                                        )
                                                    }

                                                    <p className='font-bold text-3xl'>
                                                        {p.id === "com.curriculify.premium.threemonths" ? t("threeMonths") : t("oneMonths")}
                                                    </p>
                                                    <p className='mt-2'>
                                                        {`${p.localizedPriceString}`}
                                                    </p>
                                                    {
                                                        p.id === "com.curriculify.premium.threemonths" && (
                                                            <p className='mt-2'>{`${(p.price / 3).toFixed(2)} ${currencies[p.currencyCode]} / ${t("P1M")}`}</p>
                                                        )
                                                    }
                                                </div>

                                            ))
                                        }
                                    </div>
                                    <Button fullWidth color="primary" isLoading={loading} className='mt-4' onClick={() => purchaseProduct()} >
                                        {t("continue")}
                                    </Button>
                                    {
                                        onboarding && (
                                            <Button fullWidth color="primary" variant={"light"} className='mt-4' onClick={() => n(`/app/${platform}/onboarding/completition`)} >
                                                {t("skip")}
                                            </Button>
                                        )
                                    }

                                </div>
                            </div>
                        )
                    }

                </CardBody>
            </Card>

            {
                offering && (
                    <div>
                        <p className='text-xs text-center'>{t("premiumDisclaimer")}</p>
                        <div className='flex my-3'>
                            <Link href={"#"} onClick={() => purchaseRestore()} isDisabled={loading} className='font-sans text-xs'>{t("restore")}</Link>
                            <Divider className='mx-4 h-4' orientation={"vertical"} />
                            <Link href={"#"} onClick={() => openUrl(terms, platform)} className='font-sans text-xs'>{t("terms")}</Link>
                            <Divider className='mx-4 h-4' orientation={"vertical"} />
                            <Link href={"#"} onClick={() => openUrl(privacy, platform)} className='font-sans text-xs'>{t("privacy")}</Link>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default WebPaywall