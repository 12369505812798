import { useMemo, useCallback, useRef, useState, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { produce } from "immer"
import { Cropper } from 'react-advanced-cropper';
import { getBoolean } from "firebase/remote-config";
import { httpsCallable } from "firebase/functions";

import { colors, modelCatalogue, baseImageCatalogue } from "cvpop-constants-sdk"
import { t } from "cvpop-localization-sdk"
import { bgImages } from "cvpop-assets-sdk"
import { setCv, setSpin } from "cvpop-redux-sdk"

import { useColorScheme } from "../../../../controllers/hooks";
import { goToPaywall, errorHandler, imageToBase64 } from "../../../../controllers/app";
import { functions, remoteConfig } from "../../../../controllers/firebase";
import { AlertContext } from "../../../../contexts/AlertContext";

import WebHeader from "../../../molecules/views/WebHeader"
import WebIcon from "../../../atoms/WebIcon";
import WebImageSearchModal from "../../../molecules/modals/WebImageSearchModal";


const WebBgImage = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user, userPro } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()
    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()
    const n = useNavigate()
    const inputRef = useRef()
    const cropRef = useRef()
    const cropModal = useDisclosure();
    const searchModal = useDisclosure();

    // ------------------------------------------------------------------------------------------------------------------------
    const [cropImg, setCropImg] = useState("")

    // ------------------------------------------------------------------------------------------------------------------------
    const { modelBaseColor, modelType, modelBaseImage, modelBaseImageData } = cv.Metadata

    // ------------------------------------------------------------------------------------------------------------------------
    const aiOn = useMemo(() => getBoolean(remoteConfig, "openAIOn"), [])
    const api = useMemo(() => httpsCallable(functions, "createcvbgimage", { timeout: 180000 }), [])
    const defImg = useMemo(() => modelCatalogue.find(o => o.code === (modelType || "london")).defaultImage, [modelType]);
    const customImg = useMemo(() => modelBaseImageData, [modelBaseImageData])

    // ------------------------------------------------------------------------------------------------------------------------
    const generateBgImage = useCallback(() => {

        const jobTitle = cv.SkillsPassport.LearnerInfo.Identification.ProfessionalTitle || "";

        d(setSpin(true))
        api({ jobTitle, resType: "b64_json" })
            .then(({ data }) => {
                setCropImg(`data:image/jpeg;base64,${data.b64_json}`);
                cropModal.onOpen();
            })
            .catch(e => errorHandler(alert, "err_generate_bg_image", e))
            .finally(() => d(setSpin(false)))

    }, [cv, cropModal, api, d])

    const onAction = useCallback(k => {

        if (k === "generate" && !userPro) return goToPaywall(offering, platform, n)
        if (k === "generate" && userPro) return generateBgImage();

        if (k === "upload") {
            if (!inputRef.current) return
            inputRef.current.value = ""
            return inputRef.current.click()
        }

        if (k === "search")
            return searchModal.onOpen()

    }, [userPro, offering, platform, searchModal, n, generateBgImage])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t(`bgImg`)} />

            <div className="p-5">
                {
                    baseImageCatalogue.filter(e => e.code !== "CUSTOM").map(el => (
                        <div
                            key={`bgImg${el.code}`}
                            onClick={() => d(setCv({ cv: produce(cv, d => { d.Metadata.modelBaseImage = el.code }) }))}
                            className="mb-4"
                        >
                            {
                                el.code === "0" ? (
                                    <div className={`rounded-xl h-32 p-1 border-4 border-${el.code === (modelBaseImage || defImg) ? "primary" : "default"} rounded-2xl`}>
                                        <div className={"w-full h-full p-1 rounded-lg"} style={{ backgroundColor: modelBaseColor || colors.primary }} />
                                    </div>
                                ) : (
                                    <div className={`p-1 rounded-2xl border-4 border-${el.code === (modelBaseImage || defImg) ? "primary" : "default"}`}>
                                        <img
                                            alt={`bgImage${el.code}`}
                                            src={bgImages[`bgImage${el.code}`]}
                                            className={`rounded-xl w-full h-32  object-cover rounded-2xl`}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ))
                }

                {
                    customImg && (
                        <div
                            onClick={() => d(setCv({ cv: produce(cv, d => { d.Metadata.modelBaseImage = "CUSTOM" }) }))}
                            className="mb-4"
                        >
                            <img
                                alt={"custom"}
                                src={`data:image/jpeg;base64,${customImg}`}
                                style={{ backgroundColor: "white" }}
                                className={`rounded-xl w-full h-32 p-1 object-cover border-4 border-${"CUSTOM" === (modelBaseImage || defImg) ? "primary" : "default"} rounded-2xl`}
                            />
                        </div>
                    )
                }

                <Dropdown>
                    <DropdownTrigger>
                        <Button fullWidth color="primary" variant={"ghost"}>
                            {t("add")}
                        </Button>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Static Actions" onAction={onAction} color={"primary"}>
                        {aiOn && <DropdownItem key="generate" title={t("generateAuto")} endContent={<WebIcon className={!userPro && `filled text-yellow-400`} name={userPro ? "add_photo_alternate" : "workspace_premium"} />} />}
                        <DropdownItem key="search" title={t("searchOnTheWeb")} endContent={<WebIcon name={"travel_explore"} />} />
                        <DropdownItem key="upload" title={t("import")} endContent={<WebIcon name={"upload"} />} />
                    </DropdownMenu>
                </Dropdown>

            </div>

            <Modal
                isOpen={cropModal.isOpen}
                onOpenChange={cropModal.onOpenChange}
                hideCloseButton={true}
                backdrop={"blur"}
                size={"2xl"}
                className={`${cs} text-foreground`}
            >
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">{t("cutImage")}</ModalHeader>
                    <ModalBody>
                        <Cropper ref={cropRef} src={cropImg} aspectRatio={3.08} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={cropModal.onClose}>
                            {t("cancel")}
                        </Button>
                        <Button
                            color="primary"
                            onPress={() => {
                                if (!cropRef.current) return
                                const croppedImage = cropRef.current.getCanvas().toDataURL("image/jpeg");
                                d(setCv({ cv: produce(cv, d => { d.Metadata = { ...d.Metadata, modelBaseImage: "CUSTOM", modelBaseImageData: croppedImage.split(',')[1] } }) }))
                                cropModal.onClose()
                            }}
                        >
                            {t("done")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <WebImageSearchModal
                modal={searchModal}
                onImageSelected={b64 => {
                    setCropImg(b64);
                    searchModal.onClose()
                    cropModal.onOpen();
                }}
            />

            <input
                type="file"
                className='hidden'
                accept="image/png,image/jpeg"
                ref={inputRef}
                onChange={({ target }) => {
                    if (target.files.length === 0) return
                    if (!["image/png", "image/jpeg"].includes(target.files[0].type)) return alert(t("errTitle"), t("notSupportedTypeFile"))
                    imageToBase64(target.files[0])
                        .then(b64 => {
                            setCropImg(b64);
                            cropModal.onOpen();
                        })
                        .catch(e => errorHandler(alert, "err_convert_base64", e))
                }}
            />


        </div>
    )
}

export default WebBgImage