import { useCallback, useContext, useMemo } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { User } from "@nextui-org/react";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Button, Link, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Image } from "@nextui-org/react";
import { signOut } from 'firebase/auth';
import { produce } from 'immer';

import { t } from 'cvpop-localization-sdk';
import { cvPickerItems, urls } from 'cvpop-constants-sdk';
import { logos, icons } from 'cvpop-assets-sdk';
import { setUser } from 'cvpop-redux-sdk';
import { getAvatarUser } from 'cvpop-utility-sdk';

import { errorHandler, goToPaywall, openUrl, setAppLocalization } from '../../controllers/app';
import { auth } from '../../controllers/firebase';
import { useColorScheme } from '../../controllers/hooks';
import { AlertContext } from '../../contexts/AlertContext';

import WebIcon from './WebIcon';


const WebNavbar = ({ onboarding, navbarProps }) => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user, userPro } = useSelector(state => state.userReducer)
    const { offering, version } = useSelector(state => state.appReducer)
    const { platform = "web" } = useParams()
    const { alert, confirm } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------------------------
    const { displayName, isAnonymous } = fbuser || {}; //in case of logout
    const { firstName, lastName } = user || {}
    const { legal } = urls

    // ------------------------------------------------------------------------------------------------------------------------
    const avatar = useMemo(() => getAvatarUser(user, platform) || icons.avatar, [user, platform])
    const isWeb = useMemo(() => platform === "web", [platform])

    // ------------------------------------------------------------------------------------------------------------------------
    const onProfileAction = useCallback(k => {
        if (k === "language") return
        if (k === "terms" || k === "privacy") return openUrl(legal[k], platform)
        if (k === "premium") return goToPaywall(offering, platform, n)
        if (k === "logout") return confirm(t("logout"), t("logoutMsg"))
            .then(() => signOut(auth))
            .then(() => {
                n(`/app/${platform}/onboarding`)
                d(setUser({ user: null, ignoreListener: true }))
            })
            .catch(e => errorHandler(alert, "err_logout", e))
        n(`/app/${platform}/home/${k}`)
    }, [legal, offering, platform, n, d])

    const onLogoPress = useCallback(() => {
        if (!onboarding) return n(`/app/${platform}/home/cv-list`)
        if (user?.isOnbordingSetUp) return n(`/app/${platform}/onboarding/features`)
        if (user) return n(`/app/${platform}/onboarding/first-name`)
        n(`/app/${platform}/onboarding`)
    }, [n, onboarding, platform, user])

    const onPremiumPress = useCallback(() => goToPaywall(offering, platform, n), [offering, platform, n])

    // ------------------------------------------------------------------------------------------------------------------------
    if (!isWeb) return null
    return (
        <Navbar maxWidth={"full"} className='bg-content1' isBordered {...navbarProps}>
            <NavbarBrand>
                <Image
                    radius={"none"}
                    alt={"cvpop"}
                    src={logos.apptext[cs]}
                    className='w-28 cursor-pointer'
                    onClick={onLogoPress}
                />
            </NavbarBrand>
            {
                !onboarding && (
                    fbuser ? (
                        <NavbarContent justify="end">

                            {
                                !userPro && (
                                    <NavbarItem className="mr-4">
                                        <Button
                                            variant={"light"}
                                            size={"sm"}
                                            color={"warning"}
                                            startContent={(<WebIcon name={"workspace_premium"} className={"filled text-yellow-500"} />)}
                                            onClick={onPremiumPress}
                                        >
                                            {t("passToPremium")}
                                        </Button>
                                    </NavbarItem>
                                )
                            }
                            <NavbarItem className='flex items-center'>

                                <Dropdown placement="bottom-end">
                                    <DropdownTrigger>
                                        <User
                                            as={"button"}
                                            name={!firstName && !displayName && !lastName ? t("account") : `${firstName || displayName || ""} ${lastName || ""}`}
                                            avatarProps={{ isBordered: true, color: "primary", size: "sm", src: avatar }}
                                        />
                                    </DropdownTrigger>
                                    <DropdownMenu aria-label="Profile Actions" color={"primary"} onAction={onProfileAction}>
                                        <DropdownItem key="account" title={t("account")} showDivider endContent={<WebIcon name={"account_circle"} />} />
                                        <DropdownItem
                                            isReadOnly
                                            key="language"
                                            title={t("appLanguage")}
                                            showDivider
                                            endContent={(
                                                <select
                                                    className="z-10 outline-none w-16 py-0.5 rounded-md text-tiny group-data-[hover=true]:border-default-500 border-small border-default-300 dark:border-default-200 bg-transparent text-default-500"
                                                    value={user?.language || "en"}
                                                    id="language"
                                                    name="app-language"
                                                    onChange={({ nativeEvent }) => {
                                                        d(setUser({ user: produce(user, d => { d.language = nativeEvent.target.value }) }))
                                                        setAppLocalization(nativeEvent.target.value)
                                                    }}
                                                >
                                                    {cvPickerItems.labelLanguage().map(e => (<option key={e.code} value={e.code}>{e.label}</option>))}
                                                </select>
                                            )}
                                        />
                                        <DropdownItem key="terms" title={t("terms")} endContent={<WebIcon name={"contract"} />} />
                                        <DropdownItem key="privacy" title={t("privacy")} endContent={<WebIcon name={"verified_user"} />} />
                                        <DropdownItem key="credits" title={t("credits")} showDivider={!isAnonymous} endContent={<WebIcon name={"sdk"} />} />
                                        {!isAnonymous && (<DropdownItem key="logout" title={t("logout")} endContent={<WebIcon name={"logout"} />} />)}
                                        <DropdownItem isReadOnly key="version" classNames={{ wrapper: "pt-0", base: "pt-0" }} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <span className='text-xs text-default-500'>{t("appVersion")} {version}</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>

                            </NavbarItem>
                        </NavbarContent>
                    ) : (
                        <NavbarContent justify="end">
                            <NavbarItem>
                                <Button as={Link} color="primary" href="app/web/sign" variant={"bordered"}>
                                    {t("login")}
                                </Button>
                            </NavbarItem>
                            <NavbarItem>
                                <Button id='createCvBtn' as={Link} color="primary" href="app/web" variant="solid">
                                    {t("createYourCv")}
                                </Button>
                            </NavbarItem>
                        </NavbarContent>
                    )
                )
            }

        </Navbar>
    )

}

export default WebNavbar