module.exports = {
    regex: {
        mail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },

    supportedWebPlatforms: ["web", "macos", "visionos"],

    urls: {
        apps: {
            ios: {
                mail: "https://apps.apple.com/it/app/mail/id1108187098"
            }
        },
        legal: {
            terms: "https://www.iubenda.com/terms-and-conditions/53049117",
            privacy: "https://www.iubenda.com/privacy-policy/53049117",
            cookie: "https://www.iubenda.com/privacy-policy/53049117/cookie-policy"
        },
        store: {
            appstore: "https://apps.apple.com/us/app/intelligent-cv-resume-builder/id1473493711",
            appstoredeep: "itms-apps://itunes.apple.com/us/app/apple-store/1473493711?mt=8",
            googleplay: "https://play.google.com/store/apps/details?id=com.curriculify.app",
            googleplaydeep: "market://details?id=com.curriculify.app"
        },
        subscription: {
            ios: "https://apps.apple.com/account/subscriptions",
            android: "https://play.google.com/store/account/subscriptions?package=com.curriculify.app",
            web: "https://cvpop.lemonsqueezy.com/billing"
        },
        social: {
            facebook: "https://www.facebook.com/app.cvpop",
            instagram: "https://www.instagram.com/app.cvpop",
            linkedin: "https://www.linkedin.com/company/cvpop",
            x: "https://x.com/app_cvpop",
            youtube: "https://www.youtube.com/channel/UCE1o64SOoxJoIMhXY1zDUkw",
        }
    },

    fbConfigDef: {
        removeBg: true,
        docxExport: true,
        compressCv: true,
        linkedinImport: true,

        jobSearch: true,
        courseSearch: true,
        bookSearch: true,
        salarySearch: true,
        hardPaywall: true,

        iapOn: false,
        openAIOn: true

    },

    iapConfig: {
        ios: {
            onboardingStandard: "cvpop_1299_1w_0",
            onboardingStandardTrial: "cvpop_1299_1w_3d0",
            onboardingOfferTrial: "cvpop_999_1w_3d0",
            standardShort: "cvpop_1299_1w_0",
            standardLong: "cvpop_2499_1m_0",
            discountWelcome: "cvpop_899_1w_0",
            discountStandard: "cvpop_899_1w_0",
            discountUnsubscribe: "cvpop_2499_3m_0",
            discountQuickAction: "cvpop_2499_3m_0"
        },
        android: {
            onboardingStandard: "cvpop_premium_v1:cvpop-799-1w-0",
            onboardingStandardTrial: "cvpop_premium_v1:cvpop-799-1w-3d0",
            onboardingOfferTrial: "cvpop_premium_v1:cvpop-699-1w-3d0",
            standardShort: "cvpop_premium_v1:cvpop-799-1w-0",
            standardLong: "cvpop_premium_v1:cvpop-2499-1m-0",
            discountWelcome: "cvpop_premium_v1:cvpop-699-1w-0",
            discountStandard: "cvpop_premium_v1:cvpop-699-1w-0",
            discountUnsubscribe: "cvpop_premium_v1:cvpop-2499-3m-0",
            discountQuickAction: "cvpop_premium_v1:cvpop-2499-3m-0"
        }
    }
}