import React, { useCallback, useContext, useMemo, useState } from "react";
import { Accordion, AccordionItem, Select, SelectItem, Button, Input } from "@nextui-org/react";
import { useSelector, useDispatch } from 'react-redux'
import { produce } from "immer";

import { cvPickerItems } from "cvpop-constants-sdk";
import { illustrations } from "cvpop-assets-sdk"
import { languages } from 'cvpop-collections-sdk';
import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { createFbId } from "../../../../controllers/app";
import { useColorScheme } from "../../../../controllers/hooks";
import { AlertContext } from "../../../../contexts/AlertContext";

import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";


const WebForeignLanguage = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user } = useSelector(state => state.userReducer)
    const { confirmDelete, prompt } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const { language } = user

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const itemList = useMemo(() => (cv.SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage || []), [cv])
    const languageLevels = useMemo(() => cvPickerItems.languageProficiencyLevel(), [])

    const localizedList = useMemo(() => {
        const notSetItem = { label: t("notSpecified"), code: "" }
        const currentItemList = itemList.map(({ Description }) => ({ code: Description.Code || "en", label: Description.Label }))
        return [notSetItem, ..._.orderBy(_.uniqBy([...(languages[language] || languages["en"]), ...currentItemList], "code"), [e => e.label.toLowerCase()], ['asc'])]
    }, [language, cv])

    // ------------------------------------------------------------------------------------------------------
    const [itemSelectedKeys, setItemSelectedKeys] = useState([]);
    const [newTitle, setNewTitle] = useState("")

    // ------------------------------------------------------------------------------------------------------
    const editCv = useCallback(cv => { d(setCv({ cv })) }, [cv])

    const addItem = useCallback(() => {

        const Id = createFbId();
        const newItem = {
            Id,
            Description: { Code: "", Label: "" },
            Certificate: [],
            ProficiencyLevel: { Listening: "", Reading: "", SpokenInteraction: "", SpokenProduction: "", Writing: "" },
        }
        editCv(produce(cv, d => { d.SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage.push(newItem) }))
        setItemSelectedKeys([Id])
        setNewTitle("")

    }, [cv])

    const deleteItem = useCallback(Id => {
        editCv(
            produce(cv, d => {
                const newList = d.SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage.filter(t => t.Id !== Id)
                d.SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage = newList
            })
        )
    }, [cv])

    const addItemCert = useCallback(i => {
        const Id = createFbId()
        editCv(produce(cv, d => { d.SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage[i].Certificate.push({ Id, Title: newTitle }) }))
        setNewTitle("")
    }, [cv, newTitle])

    const deleteItemCert = useCallback((i, certId) => {
        editCv(
            produce(cv, d => {
                const newList = d.SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage[i].Certificate.filter(t => t.Id !== certId)
                d.SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage[i].Certificate = newList
            })
        )
    }, [cv])

    const onAddCustomLanguage = useCallback(async i => {
        let value = null
        try { value = (await prompt(t('addNewItem'), t('addNewCustomItemMsg')) || "").trim() } catch (e) { return }
        if (value) editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage[${i}].Description`, { Code: value, Label: value })))
    }, [cv, d])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("cvLabelForeignLanguage")} />
            <div className="p-5">
                {
                    itemList.length === 0 && (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                            <WebCenterView
                                imageProps={{ src: illustrations.foreignlanguage }}
                                title={t(`cvLabelForeignLanguage`)}
                                message={t("emptyListMsg")}
                            >
                                <Button fullWidth color={"primary"} className="my-6" onClick={addItem}>{t("add")}</Button>
                            </WebCenterView>
                        </div>
                    )
                }

                <Accordion variant="splitted" selectedKeys={itemSelectedKeys} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {
                        itemList.map(({ Id, Description, ProficiencyLevel, Certificate = [] }, i) => (
                            <AccordionItem
                                isCompact
                                onPress={() => setItemSelectedKeys((itemSelectedKeys[0] || "") === Id ? [] : [Id])}
                                title={Description.Code ?
                                    _.capitalize(((languages[language] || []).find(e => e.code === Description.Code) || { label: Description.Label }).label) :
                                    t("elementWithNoTitle")}
                                key={Id}
                            >
                                <div className={`flex items-center`}>
                                    <Select
                                        label={t('language')}
                                        selectedKeys={[Description?.Code || ""]}
                                        className="mt-2"
                                        classNames={{ popoverContent: `${cs} text-foreground` }}
                                        onChange={({ target }) => {
                                            const newVal = target.value ? localizedList.find(e => e.code === target.value) : { code: "", label: "" }
                                            editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage[${i}].Description`, { Code: newVal.code, Label: newVal.label })))
                                        }}
                                    >
                                        {localizedList.map(el => (<SelectItem key={el.code} value={el.label} title={_.capitalize(el.label)} />))}
                                    </Select>
                                    <Button isIconOnly color="primary" aria-label="Like" className="mt-3 ml-3" onPress={() => onAddCustomLanguage(i)}>
                                        <span className="material-symbols-outlined">add</span>
                                    </Button>
                                </div>

                                <p className="text-xs text-default-600 font-bold uppercase ml-3 mt-4">{t("certificates")}</p>
                                {
                                    Certificate.map((cert, certI) => (
                                        <div key={`certContainer${cert.Id}`} className="flex mt-3 items-center">
                                            <Input
                                                key={`cert${cert.Id}${certI}`}
                                                label={t("certificate")}
                                                defaultValue={cert.Title}
                                                onChange={({ target }) =>
                                                    editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage[${i}].Certificate[${certI}].Title`, target.value)))
                                                }
                                            />
                                            <Button
                                                isIconOnly
                                                color="danger"
                                                aria-label="Like"
                                                className="ml-3"
                                                onPress={() => confirmDelete().then(() => deleteItemCert(i, cert.Id)).catch(() => { })}
                                            >
                                                <span className="material-symbols-outlined">delete</span>
                                            </Button>
                                        </div>
                                    ))
                                }

                                <div className={`flex mt-3 items-center`}>
                                    <Input
                                        key={"newDl"}
                                        label={t("certificate")}
                                        value={newTitle}
                                        onChange={({ target }) => setNewTitle(target.value)}
                                    />
                                    <Button isIconOnly color="primary" aria-label="Like" className="ml-3" isDisabled={!newTitle} onPress={() => addItemCert(i)}>
                                        <span className="material-symbols-outlined">add</span>
                                    </Button>
                                </div>


                                <p className="text-xs text-default-600 font-bold uppercase ml-3 mt-4">{t("autoVal")}</p>
                                <div>
                                    <Select
                                        label={t('cvLabelForeignLanguageListening')}
                                        defaultSelectedKeys={[ProficiencyLevel.Listening || ""]}
                                        className="mt-2"
                                        classNames={{ popoverContent: `${cs} text-foreground` }}
                                        onChange={({ target }) =>
                                            editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage[${i}].ProficiencyLevel.Listening`, target.value)))
                                        }
                                    >
                                        {languageLevels.map(el => (<SelectItem key={el.code} value={el.label} title={t(el.label)} />))}
                                    </Select>
                                    <Select
                                        label={t('cvLabelForeignLanguageReading')}
                                        defaultSelectedKeys={[ProficiencyLevel.Reading || ""]}
                                        className="mt-2"
                                        classNames={{ popoverContent: `${cs} text-foreground` }}
                                        onChange={({ target }) =>
                                            editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage[${i}].ProficiencyLevel.Reading`, target.value)))
                                        }
                                    >
                                        {languageLevels.map(el => (<SelectItem key={el.code} value={el.label} title={t(el.label)} />))}
                                    </Select>
                                    <Select
                                        label={t('cvLabelForeignLanguageSpokenInteraction')}
                                        defaultSelectedKeys={[ProficiencyLevel.SpokenInteraction || ""]}
                                        className="mt-2"
                                        classNames={{ popoverContent: `${cs} text-foreground` }}
                                        onChange={({ target }) =>
                                            editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage[${i}].ProficiencyLevel.SpokenInteraction`, target.value)))
                                        }
                                    >
                                        {languageLevels.map(el => (<SelectItem key={el.code} value={el.label} title={t(el.label)} />))}
                                    </Select>
                                    <Select
                                        label={t('cvLabelForeignLanguageSpokenProduction')}
                                        defaultSelectedKeys={[ProficiencyLevel.SpokenProduction || ""]}
                                        className="mt-2"
                                        classNames={{ popoverContent: `${cs} text-foreground` }}
                                        onChange={({ target }) =>
                                            editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage[${i}].ProficiencyLevel.SpokenProduction`, target.value)))
                                        }
                                    >
                                        {languageLevels.map(el => (<SelectItem key={el.code} value={el.label} title={t(el.label)} />))}
                                    </Select>
                                    <Select
                                        label={t('cvLabelForeignLanguageWriting')}
                                        defaultSelectedKeys={[ProficiencyLevel.Writing || ""]}
                                        className="mt-2"
                                        classNames={{ popoverContent: `${cs} text-foreground` }}
                                        onChange={({ target }) =>
                                            editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage[${i}].ProficiencyLevel.Writing`, target.value)))
                                        }
                                    >
                                        {languageLevels.map(el => (<SelectItem key={el.code} value={el.label} title={t(el.label)} />))}
                                    </Select>
                                </div>


                                <Button
                                    fullWidth
                                    color={"danger"}
                                    className="my-3"
                                    onPress={() => confirmDelete().then(() => deleteItem(Id)).catch(() => { })}
                                >
                                    {t("delete")}
                                </Button>

                            </AccordionItem>
                        ))
                    }
                </Accordion>

                {
                    itemList.length > 0 && (
                        <Button
                            fullWidth
                            color={"primary"}
                            variant={"ghost"}
                            className="mt-4"
                            onPress={addItem}
                        >
                            {t("addNewItem")}
                        </Button>
                    )
                }

                <WebAIAssistantButton sectionId={"FOREIGN_LANGUAGE"} />
            </div>
        </div>
    )
}

export default WebForeignLanguage