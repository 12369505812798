const renderUtility = require("./src/render")
const userUtility = require("./src/user")
const scriptUtility = require("./src/script")
const cvUtility = require("./src/cv")
const jobUtility = require("./src/job")

module.exports = {
    renderCvPreview: renderUtility.renderCvPreview,
    renderEmailPreview: renderUtility.renderEmailPreview,

    isProUser: userUtility.isProUser,
    isAmsUser: userUtility.isAmsUser,
    isEligibleForCvPhotoUser: userUtility.isEligibleForCvPhotoUser,
    isEligibleForPeriodicOffer: userUtility.isEligibleForPeriodicOffer,
    isEligibleForAdsOfferUser: userUtility.isEligibleForAdsOfferUser,
    isEligibleForOnboardingOfferUser: userUtility.isEligibleForOnboardingOfferUser,
    isEligibleForGiftUser: userUtility.isEligibleForGiftUser,
    isEligibleForAdsUser: userUtility.isEligibleForAdsUser,
    getNewUserData: userUtility.getNewUserData,
    getAvatarUser: userUtility.getAvatarUser,
    getUnsubcribeUserUrl: userUtility.getUnsubcribeUserUrl,

    compareVersions: scriptUtility.compareVersions,
    removeKeysFromObj: scriptUtility.removeKeysFromObj,
    getTextFromHtml: scriptUtility.getTextFromHtml,
    formatBytes: scriptUtility.formatBytes,
    dateToString: scriptUtility.dateToString,
    ee: scriptUtility.ee,
    sleep: scriptUtility.sleep,
    createRandomString: scriptUtility.createRandomString,
    getEnglishLabelFromLanguageCode: scriptUtility.getEnglishLabelFromLanguageCode,

    createCvObject: cvUtility.createCvObject,
    duplicateCvObject: cvUtility.duplicateCvObject,
    getLabelFromCvSection: cvUtility.getLabelFromCvSection,
    getCvSectionOrder: cvUtility.getCvSectionOrder,
    getCvSectionTextObject: cvUtility.getCvSectionTextObject,
    getCvPickerItemLabel: cvUtility.getCvPickerItemLabel,
    getCvImage: cvUtility.getCvImage,
    getCvInitials: cvUtility.getCvInitials,
    getCvReadyToTranslate: cvUtility.getCvReadyToTranslate,
    hasCvCapability: cvUtility.hasCvCapability,
    stringToCvDate: cvUtility.stringToCvDate,
    jsonToSimpleJson: cvUtility.jsonToSimpleJson,
    dataToJson: cvUtility.dataToJson,
    importDataToJson: cvUtility.importDataToJson,
    linkedinToJson: cvUtility.linkedinToJson,
    base64FileToJson: cvUtility.base64FileToJson,

    getJobSalary: jobUtility.getJobSalary,
    getJobSalaryMultiplier: jobUtility.getJobSalaryMultiplier,
    getJobIdFromUrl: jobUtility.getJobIdFromUrl,
}