import { Button } from "@nextui-org/react"
import { useParams } from "react-router-dom";

import { illustrations } from "cvpop-assets-sdk";
import { t } from "cvpop-localization-sdk";

import { auth } from "../../controllers/firebase";

import WebCenterView from '../molecules/views/WebCenterView';


const WebMaintenance = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div className="grid w-screen, h-screen place-content-center">
            <WebCenterView
                imageProps={{ src: illustrations.maintenance }}
                title={t("maintenanceMode")}
                message={t("maintenanceModeMsg")}
            >
                {
                    platform !== "web" && (
                        <Button
                            color="primary"
                            className="mt-6"
                            onPress={() => window.webkit.messageHandlers["openMail"].postMessage(auth.currentUser?.uid)}
                        >
                            {t("askAssistance")}
                        </Button>
                    )
                }
            </WebCenterView>
        </div>
    )
}

export default WebMaintenance