import { useCallback } from "react";
import { Button } from "@nextui-org/react"
import { useParams } from "react-router-dom";

import { illustrations } from "cvpop-assets-sdk";
import { t } from "cvpop-localization-sdk";
import { urls } from "cvpop-constants-sdk";

import { openUrl } from "../../controllers/app";
import { auth } from "../../controllers/firebase";

import WebCenterView from '../molecules/views/WebCenterView';


const WebForceUpdate = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------
    const onUpdatePress = useCallback(() => {
        platform === "web" ?
            window.location.reload() :
            openUrl(urls.store.appstoredeep, platform)
    }, [platform])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div className="grid w-screen, h-screen place-content-center">
            <WebCenterView
                imageProps={{ src: illustrations.update }}
                title={t(`updateAvailable`)}
                message={t(`updateAvailable${platform === "web" ? "Web" : ""}Msg`)}
            >
                <Button
                    color="primary"
                    className="mt-6"
                    onPress={onUpdatePress}
                >
                    {t("update")}
                </Button>
                {
                    platform !== "web" && (
                        <Button
                            variant={"light"}
                            color={"primary"}
                            onClick={() => window.webkit.messageHandlers["openMail"].postMessage(auth.currentUser?.uid)}
                            className="mt-3"
                        >
                            {t("askAssistance")}
                        </Button>
                    )
                }

            </WebCenterView>
        </div>
    )
}

export default WebForceUpdate