import { useEffect, useState, useCallback, useContext } from 'react'
import { useNavigate, useParams, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from "firebase/auth";
import { fetchAndActivate } from 'firebase/remote-config';
import { doc, onSnapshot } from 'firebase/firestore';
import { ref, onValue } from "firebase/database";

import { t } from 'cvpop-localization-sdk';
import { compareVersions } from 'cvpop-utility-sdk';
import { setOffering, setReviewModeEnabled, setUser, setVersion } from 'cvpop-redux-sdk';
import { urls } from 'cvpop-constants-sdk';

import { auth, db, rtDb, remoteConfig } from '../../controllers/firebase';
import { errorHandler, getLemonSqueezyCheckoutUrl, initIubendaCookieBanner, openUrl, setAppLocalization } from '../../controllers/app';
import { postLogin } from "../../controllers/user"
import { AlertContext } from '../../contexts/AlertContext';

import WebSpinnerView from '../molecules/views/WebSpinnerView';
import WebErrorView from '../molecules/views/WebErrorView';
import WebSpinnerModal from '../molecules/modals/WebSpinnerModal';
import WebPrivacyModal from '../molecules/modals/WebPrivacyModal';
import WebForceUpdate from './WebForceUpdate';
import WebMaintenance from './WebMaintenance';

import PackageJson from "../../../package.json";

const WebAppContainer = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { version } = useSelector(state => state.appReducer)
    const { language } = useSelector(state => state.webReducer)
    const { platform } = useParams()
    const { pathname } = useLocation()
    const { alert, confirm } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const [forceUpdate, setForceUpdate] = useState(false)
    const [maintenanceMode, setMaintenanceMode] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const d = useDispatch()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------------------------
    const tryAutoLogin = useCallback(fbuser => {

        setLoading(true)
        setError(false)
        postLogin({ user: fbuser, language })
            .then(user => {
                d(setUser({ user }))
                if (user?.isOnboarded) return n(`/app/${platform}/home/cv-list`)
                if (user?.isOnbordingSetUp) return n(`/app/${platform}/onboarding/features`)
                n(`/app/${platform}/onboarding/first-name`)
            })
            .catch(e => {
                errorHandler(alert, "err_autologin", e, true)
                setError(true)
            })
            .finally(() => setLoading(false))

    }, [language])

    const onForceUpdateUpdate = useCallback(snap => {

        if (!version) return

        const snapVal = snap.val();
        const compareMinVersion = compareVersions(version, snapVal.minVersion);
        const compareLatestVersion = compareVersions(version, snapVal.latestVersion);
        const compareReviewVersion = compareVersions(version, snapVal.reviewVersion);

        d(setReviewModeEnabled(compareReviewVersion === 0))
        setForceUpdate(compareMinVersion < 0)
        if (compareMinVersion >= 0 && compareLatestVersion < 0)
            confirm(t('updateAvailable'), t('updateAvailableMsg'), t('update'), t('noThanks'))
                .then(() => platform === "web" ? window.location.reload() : openUrl(urls.store.appstoredeep, platform))
                .catch(() => { })

    }, [version])

    const onGetAppVersion = useCallback(({ detail }) => d(setVersion(detail.data)), [])

    // ------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        fetchAndActivate(remoteConfig).catch(e => errorHandler(alert, "err_fetch_remote_config", e, true));

        const obs = onAuthStateChanged(auth, fbuser => {
            if (fbuser) return tryAutoLogin(fbuser)
            setLoading(false)
            return [`/app/${platform}/sign`, `/app/${platform}/sign/`].includes(pathname) ? null : n(`/app/${platform}/onboarding`)
        });
        return () => obs();
    }, [])

    useEffect(() => {

        const fuUnsb = onValue(ref(rtDb, `/forceUpdate/${platform}`), onForceUpdateUpdate);
        const mtUnsb = onValue(ref(rtDb, '/maintenance'), s => setMaintenanceMode(s.val().maintenanceMode))

        return () => {
            fuUnsb();
            mtUnsb();
        }
    }, [platform, onForceUpdateUpdate, setMaintenanceMode])

    useEffect(() => {
        if (!fbuser || !user) return

        if (user && user.isMarketing) setAppLocalization("en")
        if (platform === "web") initIubendaCookieBanner()
        platform === "web" ?
            d(setOffering(getLemonSqueezyCheckoutUrl(fbuser, user))) :
            d(setOffering(null))

    }, [fbuser, user, platform])


    useEffect(() => {
        if (!fbuser || !user) return
        const unsub = onSnapshot(doc(db, "00_USERS_00", fbuser.uid), doc => d(setUser({ user: doc.data(), ignoreListener: true })));
        return () => unsub()
    }, [db, fbuser])

    useEffect(() => {
        window.addEventListener('onGetAppVersion', onGetAppVersion)
        platform === "web" ?
            onGetAppVersion({ detail: { data: PackageJson.version } }) :
            window.webkit?.messageHandlers["getAppVersion"]?.postMessage("");
        return () => window.removeEventListener('onGetAppVersion', onGetAppVersion)
    }, [platform])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <>
            {
                loading ? (
                    <WebSpinnerView />
                ) : error ? (
                    <WebErrorView buttonProps={{ onClick: tryAutoLogin }} />
                ) : forceUpdate ? (
                    <WebForceUpdate />
                ) : maintenanceMode ? (
                    <WebMaintenance />
                ) : (
                    <div>
                        <Outlet />
                    </div>
                )}

            <WebPrivacyModal />
            <WebSpinnerModal />
        </>
    )
}

export default WebAppContainer