const { defaultModelLayout, modelCatalogue } = require("cvpop-constants-sdk");

const { getCvSectionOrder } = require("../../cv");

const { displayName } = require("../partials/display-name");
const { profilePic } = require("../partials/profile-pic");
const { signature } = require("../partials/signature");
const { video } = require("../partials/video");
const { achievements } = require("../partials/achievements");
const { attachments } = require("../partials/attachments");
const { portfolio } = require("../partials/portfolio");
const { abilites } = require("../partials/abilities");
const { hobbies } = require("../partials/hobbies");
const { skills } = require("../partials/skills");
const { education } = require("../partials/education");
const { work } = require("../partials/work");
const { expertiseAreas } = require("../partials/expertise-areas");
const { headline } = require("../partials/headline");
const { driverLicencesSpecial } = require("../partials/driver-licences-special");
const { driverLicences } = require("../partials/driver-licences");
const { summary } = require("../partials/summary");
const { contactInfo } = require("../partials/contact-info");
const { language } = require("../partials/languages");
const { foreignLanguage } = require("../partials/foreign-languages");
const { foreignLanguageTable } = require("../partials/foreign-languages-table");


const getSection = (cv = {}, name = "", largeMode = false) => {
    switch (name) {
        case "contact-info": return contactInfo(cv)
        case "languages": return language(cv)
        case "summary": return summary(cv)
        case "foreign-languages": return largeMode ? foreignLanguageTable(cv) : foreignLanguage(cv)
        case "driver-licences": return driverLicences(cv)
        case "driver-licences-special": return driverLicencesSpecial(cv)
        case "headline": return headline(cv)
        case "expertise-areas": return expertiseAreas(cv)
        case "work": return work(cv)
        case "education": return education(cv)
        case "skills": return skills(cv)
        case "abilities": return abilites(cv)
        case "portfolio": return portfolio(cv)
        case "attachments": return attachments(cv)
        case "achievements": return achievements(cv)
        case "hobbies": return hobbies(cv)
        case "video": return video(cv)
        default: return ""
    }
}

const getDefaultSectionBody = (cv, arrangement, type) => {

    if (type === "single_column") return `
        <div class="body">
            ${arrangement.center.map(section => getSection(cv, section, true)).join("")}
            ${signature(cv)}
        </div>
    `

    if (type === "left_column") return `
        <div class="body">
            <div class="left-column">
                ${arrangement.left.map(section => getSection(cv, section)).join("")}
            </div>
            <div class="right-column">
                ${arrangement.right.map(section => getSection(cv, section, true)).join("")}
                ${signature(cv)}
            </div>
        </div>
    `

    if (type === "half_single_column") return `
        <div class="body">
            <div class="half-column">
                ${arrangement.left.map(section => getSection(cv, section)).join("")}
            </div>
            <div class="half-column">
                ${arrangement.right.map(section => getSection(cv, section)).join("")}
            </div>
            <div class="unique-column">
                ${arrangement.center.map(section => getSection(cv, section, true)).join("")}
                ${signature(cv)}
            </div>
        </div>
    `

}

module.exports = {
    getTemplateBody: cv => {

        const { modelType = "london", modelBasePattern, modelBaseImage } = cv.Metadata

        const baseImage = modelBaseImage || modelCatalogue.find(o => o.code === modelType).defaultImage;
        const basePattern = modelBasePattern || modelCatalogue.find(o => o.code === modelType).defaultPattern;

        // aggiunog controllo addizionale se layout salvato è coerento con il modello (non dovrebbe mai accadere ma verificato crash in prod)
        const modelArrangement = defaultModelLayout[modelCatalogue.find(o => o.code === modelType).layout]
        const cvArrangement = getCvSectionOrder(cv) || modelArrangement;
        const arrangement = (JSON.stringify(Object.keys(cvArrangement).sort()) !== JSON.stringify(Object.keys(modelArrangement).sort())) ? modelArrangement : cvArrangement

        if (["amsterdam"].includes(modelType)) return `
        <div class="page">
            <div class="body">
            <div class="left-column">
                ${profilePic(cv)}
                ${arrangement.left.map(section => getSection(cv, section)).join("")}
            </div>
            <div class="right-column">
                <div class="header bg-pattern-${basePattern}">
                    ${displayName(cv)}
                </div>
                    ${arrangement.right.map(section => getSection(cv, section, true)).join("")}
                    ${signature(cv)}
                </div>
            </div>
        </div>
    `

        if (["atene", "moscow"].includes(modelType)) return `
        <div class="page">
            <div class="body">
                <div class="left-column">
                    ${profilePic(cv)}
                    ${arrangement.left.map(section => getSection(cv, section)).join("")}
                </div>
                <div class="right-column">
                    ${displayName(cv)}
                    ${arrangement.right.map(section => getSection(cv, section, true)).join("")}
                    ${signature(cv)}
                </div>
            </div>
        </div>
    `

        if (["bruxelles"].includes(modelType)) return `
        <div class="page">
            <div class="header">
                ${displayName(cv)}
                ${profilePic(cv)}
            </div>
            ${getDefaultSectionBody(cv, arrangement, "single_column")}
        </div>
    `

        if (["dublin"].includes(modelType)) return `
        <div class="page">
            <div class="header bg-image-${baseImage}">
                ${profilePic(cv)}
                ${displayName(cv)}
            </div>
            ${getDefaultSectionBody(cv, arrangement, "half_single_column")}
        </div>
    `

        if (["lisbon"].includes(modelType)) return `
        <div class="page">
            <div class="header bg-image-${baseImage}">
                ${displayName(cv)}
                ${profilePic(cv)}
            </div>
            ${getDefaultSectionBody(cv, arrangement, "left_column")}
        </div>
    `

        if (["helsinki"].includes(modelType)) return `
        <div class="page">
            <div class="header-top"></div>
            <div class="header">
                ${profilePic(cv)}
                ${displayName(cv)}
            </div>
            ${getDefaultSectionBody(cv, arrangement, "half_single_column")}
        </div>
    `

        if (["istanbul"].includes(modelType)) return `
        <div class="page">
            <div class="header">
                ${profilePic(cv)}
                ${displayName(cv)}
            </div>
            ${getDefaultSectionBody(cv, arrangement, "half_single_column")}
        </div>
    `

        if (["rio"].includes(modelType)) return `
        <div class="page">
            <div class="header">
                ${profilePic(cv)}
                ${displayName(cv)}
            </div>
            ${getDefaultSectionBody(cv, arrangement, "half_single_column")}
        </div>
    `

        if (modelType === "london") return `
        <div class="page">
            <div class="header-top"></div>
            <div class="header">
                ${displayName(cv)}
                ${profilePic(cv)}
            </div>
            ${getDefaultSectionBody(cv, arrangement, "left_column")}
        </div>
    `

        if (["madrid", "sydney"].includes(modelType)) return `
        <div class="page">
            <div class="header">
                <div class="header-pic bg-pattern-${basePattern}">
                    ${profilePic(cv)}
                </div>
                ${displayName(cv)}
            </div>
            ${getDefaultSectionBody(cv, arrangement, "single_column")}
        </div>
    `

        if (["milan"].includes(modelType)) return `
        <div class="page">
            <div class="header bg-pattern-${basePattern}">
                ${profilePic(cv)}
                ${displayName(cv)}
            </div>
            ${getDefaultSectionBody(cv, arrangement, "single_column")}
        </div>
    `

        if (["stockholm"].includes(modelType)) return `
        <div class="page">
            <div class="header bg-pattern-${basePattern}">
                ${displayName(cv)}  
                ${profilePic(cv)}
            </div>
            ${getDefaultSectionBody(cv, arrangement, "single_column")}
        </div>
    `

        if (["newyork"].includes(modelType)) return `
        <div class="page">
            <div class="header bg-pattern-${basePattern}">
                ${displayName(cv)}
                ${profilePic(cv)}
            </div>
            ${getDefaultSectionBody(cv, arrangement, "single_column")}
        </div>
    `

        if (["paris"].includes(modelType)) return `
        <div class="page">
            <div class="header bg-pattern-${basePattern}">
                ${profilePic(cv)}
                ${displayName(cv)}
            </div>
            ${getDefaultSectionBody(cv, arrangement, "half_single_column")}
        </div>
    `

        if (["pechino"].includes(modelType)) return `
        <div class="page">
            <div class="header">
                <div class="header-divider"></div>
                <div class="header-content">
                    ${profilePic(cv)}
                    ${displayName(cv)}
                </div>
            </div>
            ${getDefaultSectionBody(cv, arrangement, "half_single_column")}
        </div>
    `

        if (["shanghai"].includes(modelType)) return `
        <div class="page">
            <div class="body">
                <div class="left-column">
                    <div class="left-column-header bg-pattern-${basePattern}">
                        ${profilePic(cv)}
                        ${displayName(cv)}
                    </div>
                    ${arrangement.left.map(section => getSection(cv, section)).join("")}
                </div>
                <div class="right-column">
                    ${arrangement.right.map(section => getSection(cv, section, true)).join("")}
                    ${signature(cv)}
                </div>
            </div>
        </div>
    `

        if (["tokyo"].includes(modelType)) return `
        <div class="page">
            <div class="body">
                <div class="left-column">
                    ${profilePic(cv)}
                    ${displayName(cv)}
                    ${arrangement.left.map(section => getSection(cv, section)).join("")}
                </div>
                <div class="right-column">
                    ${arrangement.right.map(section => getSection(cv, section, true)).join("")}
                    ${signature(cv)}
                </div>
            </div>
        </div>
    `
        return ""
    }
}