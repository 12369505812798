import { useCallback, useEffect, useState } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input } from "@nextui-org/react";

import { t } from "cvpop-localization-sdk";

import { useColorScheme } from "../../controllers/hooks";

const WebAlert = ({ title, message, actionText, cancelText, actionStyle, isOpen, prompt, promptDefaultValue, onActionPress, onCancelPress }) => {

    // ------------------------------------------------------------------------------------------------------------------------
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------------------------
    const [promptText, setPromtText] = useState(promptDefaultValue || "")

    // ------------------------------------------------------------------------------------------------------------------------
    const onAction = useCallback(action => {
        setPromtText("")
        action === "cancel" ? onCancelPress() : onActionPress(promptText)
    }, [promptText, onCancelPress, onActionPress])

    // ------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (isOpen && promptDefaultValue) setPromtText(promptDefaultValue)
    }, [isOpen, promptDefaultValue])


    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <Modal
            isOpen={isOpen}
            scrollBehavior={"inside"}
            backdrop={"blur"}
            className={`${cs} text-foreground`}
            hideCloseButton
        >
            <ModalContent>
                <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
                <ModalBody>
                    <p>
                        {message}
                    </p>
                    {
                        prompt && (
                            <Input
                                autoFocus
                                placeholder={t("writeHerePh")}
                                defaultValue={promptText}
                                value={promptText}
                                onChange={({ target }) => setPromtText(target.value)}
                                onKeyDown={({ key }) => ["Enter", "Escape"].includes(key) && onAction(key === "Enter" ? "confirm" : "cancel")}
                            />
                        )
                    }
                </ModalBody>
                <ModalFooter>
                    {
                        cancelText && (
                            <Button color={"default"} variant="light" onPress={() => onAction("cancel")}>
                                {cancelText}
                            </Button>
                        )
                    }

                    <Button color={actionStyle || "primary"} onPress={() => onAction("confirm")}>
                        {actionText}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default WebAlert