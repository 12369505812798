import { useCallback, useContext, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, Outlet, useParams, useLocation } from "react-router-dom";
import { Avatar, Listbox, ListboxItem, Button } from "@nextui-org/react";
import { httpsCallable } from "firebase/functions";

import { icons } from "cvpop-assets-sdk";
import { getJobIdFromUrl, getJobSalary } from "cvpop-utility-sdk";
import { setJobList } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk";

import _ from "lodash"

import { functions } from "../../../../controllers/firebase";
import { errorHandler, getCountryCodeFromCity } from "../../../../controllers/app";
import { AlertContext } from "../../../../contexts/AlertContext";


const WebJobContainer = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { jobList } = useSelector(state => state.jobReducer)
    const { user } = useSelector(state => state.userReducer)
    const { platform, jobId } = useParams()
    const { pathname } = useLocation()
    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const { language } = user

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------------------------
    const countryCode = useMemo(() => getCountryCodeFromCity(), []);
    const jobApi = useMemo(() => httpsCallable(functions, "getjoblistv2"), []);
    const isWeb = useMemo(() => platform === "web", [platform])

    // ------------------------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(2)
    const [more, setMore] = useState(true)

    // ------------------------------------------------------------------------------------------------------------------------
    const { professionalTitle, location } = user || {}

    // ------------------------------------------------------------------------------------------------------
    const getJobs = useCallback(() => {

        setLoading(true)
        jobApi({ jobTitle: professionalTitle, position: location, page: `${page}`, pageSize: "10", language, countryCode })
            .then(({ data }) => {
                if (data.length < 6) return setMore(false)
                d(setJobList([...jobList, ...data]))
                setPage(page + 1)
            })
            .catch(e => errorHandler(alert, "err_search_jobs", e))
            .finally(() => setLoading(false))

    }, [professionalTitle, location, countryCode, language, page, jobList])

    // ------------------------------------------------------------------------------------------------------------------------ 
    return (
        <div className="flex" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>

            <div className="w-4/12  overflow-y-auto bg-content1 p-1 pb-8 border-r border-divider" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>

                <Listbox
                    variant={"flat"}
                    onAction={k => n(k)}
                    disabledKeys={[jobId]}
                    classNames={{ base: "pb-0" }}
                    bottomContent={more && (
                        <Button color={"primary"} className="mt-4" isLoading={loading} onClick={() => getJobs()}>
                            {t("showMore")}
                        </Button>
                    )}
                >
                    {
                        jobList.map(({ title, company, locations, url, salary_min, salary_max, salary_currency_code, salary_type }, i) => {
                            const { min, max, currency, period } = getJobSalary({ salary_min, salary_max, salary_currency_code, salary_type }) || {}
                            return (
                                <ListboxItem
                                    key={getJobIdFromUrl(url)}
                                    startContent={(<Avatar radius="sm" className="bg-transparent" imgProps={{ className: "object-contain p-1" }} src={icons.phcompanylogo} name={company} />)}
                                    title={title}
                                    description={_.compact([company, locations, (min || max) && (min === max ? `${min} - ${max}${currency} / ${t(period)}` : `${min}${currency} / ${t(period)}`)]).join(" - ")}
                                    className={jobId === getJobIdFromUrl(url) && `opacity-100 text-primary-foreground bg-primary`}
                                    classNames={{ title: "whitespace-normal font-semibold", description: jobId === getJobIdFromUrl(url) && "text-primary-50" }}
                                    showDivider={i < jobList.length - 1}
                                />
                            )
                        })
                    }
                </Listbox>

            </div>

            {
                ([`/app/${platform}/home/job-list`, `/app/${platform}/home/job-list/`].includes(pathname)) ? (
                    <div className="w-8/12 bg-content1 flex items-center justify-center" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>
                        <p className="text-3xl text-center text-default p-8">{t("noItemsSelected")}</p>
                    </div>
                ) : (
                    <Outlet />
                )
            }
        </div>
    )
}

export default WebJobContainer