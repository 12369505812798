import { useMemo } from "react"
import { hasCvCapability } from "cvpop-utility-sdk"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, Outlet, useLocation, useParams } from "react-router-dom";
import { colors, fontCatalogue, abilityFormatCatalogue } from "cvpop-constants-sdk"
import { Listbox, ListboxItem, Button, ButtonGroup, Card, CardBody, Popover, PopoverTrigger, PopoverContent, Divider } from "@nextui-org/react";
import { Accordion, AccordionItem, Select, SelectItem } from "@nextui-org/react";

import { produce } from "immer"

import { t } from "cvpop-localization-sdk"
import { setCv } from "cvpop-redux-sdk"

import _ from "lodash"

import WebColorPickerView from "../../../molecules/views/WebColorPickerView";
import WebCommonTextView from "../../../molecules/views/WebCommonTextView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebListItemHeaderView from "../../../molecules/views/WebListItemHeaderView";

import { useColorScheme } from "../../../../controllers/hooks";


const WebCVStyle = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { platform } = useParams()
    const { pathname } = useLocation()

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------------------------
    const { modelAbilityFormat, modelBgExAreaFormat, signatureDim, signatureOnLeft, titlesInUppercase, titlesInUnderline } = cv.Metadata
    const { modelBaseFont, modelNameFont, modelTitleFont, modelBaseFontSize, modelNameFontSize } = cv.Metadata
    const { modelBaseColor, modelAbilityColor, modelExAreaColor, modelNameColor, modelTitleColor } = cv.Metadata
    const { WorkExperience = [], Education = [], Achievement = [], Skills } = cv.SkillsPassport.LearnerInfo
    const { Communication, Organisational, Computer, JobRelated, Other } = Skills


    // ------------------------------------------------------------------------------------------------------------------------
    const keywordFormatList = useMemo(() => [
        { label: t("circular"), code: 15 },
        { label: t("rounded"), code: 5 },
        { label: t("square"), code: -1 }
    ], [])

    const abilityFormatList = useMemo(() => [
        { label: t("singleBar"), code: 'abilityFormatSingleBar' },
        { label: t("circular"), code: 'abilityFormatCircular' },
        { label: t("dots"), code: 'abilityFormatDots' },
        { label: t("squares"), code: 'abilityFormatSquares' },
        { label: t("checks"), code: 'abilityFormatChecks' },
        { label: t("bars"), code: 'abilityFormatBars' },
        { label: t("stars"), code: 'abilityFormatStars' }
    ], [])

    const signatureFormatList = useMemo(() => [
        { label: t("small"), code: "SMALL" },
        { label: t("standard"), code: "STANDARD" },
        { label: t("large"), code: "LARGE" },
    ], [])


    const cvLI = useMemo(() => cv.SkillsPassport.LearnerInfo, [cv])
    const cvIdf = useMemo(() => cvLI.Identification, [cvLI])
    const cvPhoto = useMemo(() => cvIdf.Photo && cvIdf.Photo.Data !== "", [cvIdf])
    const cvKeys = useMemo(() => cvLI.ExpertiseArea || [], [cvLI])
    const cvGraphs = useMemo(() => cvLI.Ability || [], [cvLI])
    const cvSign = useMemo(() => cvIdf.Signature, [cvIdf])

    const keywordFormat = useMemo(() => (modelBgExAreaFormat || 5), [modelBgExAreaFormat])
    const abilityFormat = useMemo(() => (modelAbilityFormat || "abilityFormatSingleBar"), [modelAbilityFormat])

    const pfCap = useMemo(() => hasCvCapability(cv, 'photo_format'), [cv])
    const bpCap = useMemo(() => hasCvCapability(cv, 'bg_pattern'), [cv])
    const biCap = useMemo(() => hasCvCapability(cv, 'bg_image'), [cv])

    const isFontChangeOn = useMemo(() => (!["zh", "ja"].includes(cv.Metadata.lng)), [cv])


    // ------------------------------------------------------------------------------------------------------------------------
    if (![`/app/${platform}/cv/style/`, `/app/${platform}/cv/style`].includes(pathname)) return <Outlet />
    return (
        <div>
            <WebHeader title={t("style")} />
            <div className="px-5 pb-5">

                <WebListItemHeaderView text={t('cvLabelPersonalInfo')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">
                        <Listbox
                            aria-label="Actions"
                            variant={"flat"}
                        >
                            <ListboxItem
                                key="model"
                                className="pr-0"
                                onClick={() => n(`/app/${platform}/cv/style/model`)}
                                textValue={t('model')}
                                startContent={<span className="material-symbols-outlined">space_dashboard</span>}
                                endContent={<span className="material-symbols-outlined text-default-400">chevron_right</span>}
                            >
                                {t('model')}
                            </ListboxItem>
                            <ListboxItem
                                key="base-color"
                                className="pr-0"
                                textValue={t('color')}
                                startContent={<span className="material-symbols-outlined">colors</span>}
                                endContent={(
                                    <>
                                        <span className="w-12 h-6 rounded" style={{ backgroundColor: (modelBaseColor || colors.primary) }} />
                                        <span className="material-symbols-outlined text-default-400">chevron_right</span>
                                    </>
                                )}
                            >
                                <Popover backdrop={"blur"} className={`${cs} text-foreground`}>
                                    <PopoverTrigger>
                                        {t('color')}
                                    </PopoverTrigger>
                                    <PopoverContent className="p-0">
                                        <WebColorPickerView
                                            onChange={c => d(setCv({ cv: produce(cv, d => { d.Metadata.modelBaseColor = c }) }))}
                                            color={(modelBaseColor || colors.primary)}
                                        />
                                    </PopoverContent>
                                </Popover>

                            </ListboxItem>

                            {(pfCap && cvPhoto) && (
                                <ListboxItem
                                    key="photo-format"
                                    className="pr-0"
                                    textValue={t('photoFormat')}
                                    onClick={() => n(`/app/${platform}/cv/style/photo-format`)}
                                    startContent={<span className="material-symbols-outlined">category</span>}
                                    endContent={<span className="material-symbols-outlined text-default-400">chevron_right</span>}
                                >
                                    {t('photoFormat')}
                                </ListboxItem>
                            )}

                            {bpCap && (
                                <ListboxItem
                                    key="header-texture"
                                    className="pr-0"
                                    textValue={t('texture')}
                                    onClick={() => n(`/app/${platform}/cv/style/header-texture`)}
                                    startContent={<span className="material-symbols-outlined">texture</span>}
                                    endContent={<span className="material-symbols-outlined text-default-400">chevron_right</span>}
                                >
                                    {t('texture')}
                                </ListboxItem>
                            )}

                            {biCap && (
                                <ListboxItem
                                    key="header-image"
                                    className="pr-0"
                                    textValue={t('bgImg')}
                                    onClick={() => n(`/app/${platform}/cv/style/header-image`)}
                                    startContent={<span className="material-symbols-outlined">wallpaper</span>}
                                    endContent={<span className="material-symbols-outlined text-default-400">chevron_right</span>}
                                >
                                    {t('bgImg')}
                                </ListboxItem>
                            )}

                        </Listbox>
                    </CardBody>
                </Card>

                {
                    _.some([cvKeys.length > 0, cvGraphs.length > 0, (cvSign && cvSign.Data)]) && (
                        <>
                            <WebListItemHeaderView text={t('sections')} />
                            <Card shadow={"none"}>
                                <CardBody className="p-2">
                                    <Accordion isCompact showDivider={false}>
                                        {cvKeys.length > 0 && (
                                            <AccordionItem
                                                key="1"
                                                aria-label="Accordion 1"
                                                textValue={t("cvLabelKeyword")}
                                                title={t("cvLabelKeyword")}
                                                startContent={<span className="material-symbols-outlined flex">label</span>}
                                                classNames={{ title: "text-small" }}
                                            >
                                                <div className="flex items-stretch pb-2">
                                                    <Select
                                                        label={t("format")}
                                                        size={"sm"}
                                                        className="mr-4"
                                                        disallowEmptySelection
                                                        classNames={{ popoverContent: `${cs} text-foreground` }}
                                                        defaultSelectedKeys={[String(keywordFormat)]}
                                                        onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.modelBgExAreaFormat = target.value }) }))}
                                                    >
                                                        {keywordFormatList.map(({ label, code }) => (
                                                            <SelectItem key={code} value={code} textValue={label}>
                                                                {label}
                                                            </SelectItem>
                                                        ))}
                                                    </Select>

                                                    <Popover backdrop={"blur"} className={`${cs} text-foreground`}>
                                                        <PopoverTrigger>
                                                            <div className="w-24 self-stretch rounded-xl" style={{ backgroundColor: modelExAreaColor || (modelBaseColor || colors.primary) }} />
                                                        </PopoverTrigger>
                                                        <PopoverContent className="p-0">
                                                            <WebColorPickerView
                                                                onChange={c => d(setCv({ cv: produce(cv, d => { d.Metadata.modelExAreaColor = c }) }))}
                                                                color={modelExAreaColor || (modelBaseColor || colors.primary)}
                                                            />
                                                        </PopoverContent>
                                                    </Popover>

                                                </div>
                                            </AccordionItem>
                                        )}

                                        {cvGraphs.length > 0 && (
                                            <AccordionItem
                                                key="2"
                                                aria-label="Accordion 2"
                                                textValue={t("cvLabelAbility")}
                                                title={t("cvLabelAbility")}
                                                startContent={<span className="material-symbols-outlined flex">align_horizontal_left</span>}
                                                classNames={{ title: "text-small" }}
                                            >
                                                <div className="flex items-stretch pb-2">
                                                    <Select
                                                        label={t("format")}
                                                        size={"sm"}
                                                        className="mr-4"
                                                        disallowEmptySelection
                                                        classNames={{ popoverContent: `${cs} text-foreground` }}
                                                        defaultSelectedKeys={[String(abilityFormat)]}
                                                        onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.modelAbilityFormat = target.value }) }))}
                                                    >
                                                        {abilityFormatCatalogue.map(({ label, code }) => (
                                                            <SelectItem key={code} value={code} textValue={t(label)}>
                                                                {t(label)}
                                                            </SelectItem>
                                                        ))}
                                                    </Select>

                                                    <Popover backdrop={"blur"} className={`${cs} text-foreground`}>
                                                        <PopoverTrigger>
                                                            <div className="w-24 self-stretch rounded-xl" style={{ backgroundColor: modelAbilityColor || (modelBaseColor || colors.primary) }} />
                                                        </PopoverTrigger>
                                                        <PopoverContent className="p-0">
                                                            <WebColorPickerView
                                                                onChange={c => d(setCv({ cv: produce(cv, d => { d.Metadata.modelAbilityColor = c }) }))}
                                                                color={modelAbilityColor || (modelBaseColor || colors.primary)}
                                                            />
                                                        </PopoverContent>
                                                    </Popover>

                                                </div>
                                            </AccordionItem>
                                        )}

                                        {cvSign && cvSign.Data && (
                                            <AccordionItem
                                                key="3"
                                                aria-label="Accordion 3"
                                                textValue={t("signature")}
                                                title={t("signature")}
                                                startContent={<span className="material-symbols-outlined flex">signature</span>}
                                                classNames={{ title: "text-small" }}
                                            >
                                                <div className="flex items-stretch pb-2">
                                                    <Select
                                                        label={t("size")}
                                                        size={"sm"}
                                                        disallowEmptySelection
                                                        classNames={{ popoverContent: `${cs} text-foreground` }}
                                                        defaultSelectedKeys={[String(signatureDim || 'STANDARD')]}
                                                        onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.signatureDim = target.value }) }))}

                                                    >
                                                        {signatureFormatList.map(({ label, code }) => (
                                                            <SelectItem key={code} value={code} textValue={label}>
                                                                {label}
                                                            </SelectItem>
                                                        ))}
                                                    </Select>

                                                    <ButtonGroup className="ml-4">
                                                        <Button
                                                            isIconOnly
                                                            color={"primary"}
                                                            size={"lg"}
                                                            variant={signatureOnLeft ? "solid" : "bordered"}
                                                            onPress={() => d(setCv({ cv: produce(cv, d => { d.Metadata.signatureOnLeft = true }) }))}
                                                        >
                                                            <span className="material-symbols-outlined text-3xl">
                                                                format_align_left
                                                            </span>
                                                        </Button>
                                                        <Button
                                                            isIconOnly
                                                            color={"primary"}
                                                            size={"lg"}
                                                            variant={!signatureOnLeft ? "solid" : "bordered"}
                                                            onPress={() => d(setCv({ cv: produce(cv, d => { d.Metadata.signatureOnLeft = false }) }))}
                                                        >
                                                            <span className="material-symbols-outlined text-3xl">
                                                                format_align_right
                                                            </span>
                                                        </Button>
                                                    </ButtonGroup>

                                                </div>
                                            </AccordionItem>
                                        )}
                                    </Accordion>
                                </CardBody>
                            </Card>
                        </>
                    )}

                <WebListItemHeaderView text={t('texts')} />
                <Card shadow={"none"}>
                    <CardBody className="p-2">
                        <Accordion isCompact showDivider={false}>

                            <AccordionItem key="1" aria-label="Accordion 1" textValue={t("document")} title={t("document")} classNames={{ title: "text-small" }}>
                                <div className="flex items-stretch pb-2">
                                    {
                                        isFontChangeOn && (
                                            <Select
                                                label={t("font")}
                                                size={"sm"}
                                                disallowEmptySelection
                                                defaultSelectedKeys={[modelBaseFont || "Montserrat"]}
                                                className="mr-4"
                                                classNames={{ popoverContent: `${cs} text-foreground` }}
                                                onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.modelBaseFont = target.value }) }))}
                                            >
                                                {fontCatalogue.map(({ name }) => (
                                                    <SelectItem key={name} value={name} textValue={name} style={{ fontFamily: `${name}, sans-serif` }}>
                                                        {name}
                                                    </SelectItem>
                                                ))}
                                            </Select>
                                        )
                                    }


                                    <ButtonGroup>
                                        <Button
                                            isIconOnly
                                            color={"primary"}
                                            size={"lg"}
                                            isDisabled={(modelBaseFontSize || 11) <= 6}
                                            onClick={() => d(setCv({ cv: produce(cv, d => { d.Metadata.modelBaseFontSize = (d.Metadata.modelBaseFontSize || 11) - 1 }) }))}
                                        >
                                            <span className="material-symbols-outlined text-3xl">text_decrease</span>
                                        </Button>
                                        <Divider orientation={"vertical"} />
                                        <Button
                                            isIconOnly
                                            color={"primary"}
                                            size={"lg"}
                                            isDisabled={(modelBaseFontSize || 11) >= 18}
                                            onClick={() => d(setCv({ cv: produce(cv, d => { d.Metadata.modelBaseFontSize = (d.Metadata.modelBaseFontSize || 11) + 1 }) }))}
                                        >
                                            <span className="material-symbols-outlined text-3xl">text_increase</span>
                                        </Button>
                                    </ButtonGroup>

                                </div>
                            </AccordionItem>

                            <AccordionItem key="2" aria-label="Accordion 2" textValue={t("nameAndSurname")} title={t("nameAndSurname")} classNames={{ title: "text-small" }}>
                                <div className="flex items-stretch pb-2">
                                    {
                                        isFontChangeOn && (
                                            <Select
                                                label={t("font")}
                                                size={"sm"}
                                                disallowEmptySelection
                                                className="mr-4"
                                                classNames={{ popoverContent: `${cs} text-foreground` }}
                                                defaultSelectedKeys={[modelNameFont || modelBaseFont || "Montserrat"]}
                                                onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.modelNameFont = target.value }) }))}
                                            >
                                                {fontCatalogue.map(({ name }) => (
                                                    <SelectItem key={name} value={name} textValue={name} style={{ fontFamily: `${name}, sans-serif` }}>
                                                        {name}
                                                    </SelectItem>
                                                ))}
                                            </Select>
                                        )
                                    }


                                    <ButtonGroup>
                                        <Button
                                            isIconOnly
                                            color={"primary"}
                                            size={"lg"}
                                            isDisabled={(modelNameFontSize || 48) <= 41}
                                            onClick={() => d(setCv({ cv: produce(cv, d => { d.Metadata.modelNameFontSize = (d.Metadata.modelNameFontSize || 48) - 1 }) }))}
                                        >
                                            <span className="material-symbols-outlined text-3xl">text_decrease</span>
                                        </Button>
                                        <Divider orientation={"vertical"} />
                                        <Button
                                            isIconOnly
                                            color={"primary"}
                                            size={"lg"}
                                            isDisabled={(modelNameFontSize || 48) >= 55}
                                            onClick={() => d(setCv({ cv: produce(cv, d => { d.Metadata.modelNameFontSize = (d.Metadata.modelNameFontSize || 48) + 1 }) }))}
                                        >
                                            <span className="material-symbols-outlined text-3xl">text_increase</span>
                                        </Button>
                                    </ButtonGroup>

                                    {
                                        hasCvCapability(cv, 'name_color') && (
                                            <Popover backdrop={"blur"} className={`${cs} text-foreground`}>
                                                <PopoverTrigger>
                                                    <div className="w-24 self-stretch rounded-xl ml-4" style={{ backgroundColor: modelNameColor || "black" }} />
                                                </PopoverTrigger>
                                                <PopoverContent className="p-0">
                                                    <WebColorPickerView
                                                        onChange={c => d(setCv({ cv: produce(cv, d => { d.Metadata.modelNameColor = c }) }))}
                                                        color={(modelNameColor || "black")}
                                                    />
                                                </PopoverContent>
                                            </Popover>
                                        )
                                    }

                                </div>
                            </AccordionItem>

                            <AccordionItem key="3" aria-label="Accordion 3" textValue={t("sectionTitles")} title={t("sectionTitles")} classNames={{ title: "text-small" }}>

                                <div className="flex items-stretch pb-2">
                                    {
                                        isFontChangeOn && (
                                            <Select
                                                label={t("font")}
                                                size={"sm"}
                                                className="mr-4"
                                                classNames={{ popoverContent: `${cs} text-foreground` }}
                                                disallowEmptySelection
                                                defaultSelectedKeys={[modelTitleFont || modelBaseFont || 'Montserrat']}
                                                onChange={({ target }) => d(setCv({ cv: produce(cv, d => { d.Metadata.modelTitleFont = target.value }) }))}
                                            >
                                                {fontCatalogue.map(({ name }) => (
                                                    <SelectItem key={name} value={name} textValue={name} style={{ fontFamily: `${name}, sans-serif` }}>
                                                        {name}
                                                    </SelectItem>
                                                ))}
                                            </Select>
                                        )
                                    }


                                    <ButtonGroup className="mr-4">
                                        <Button
                                            isIconOnly
                                            color={"primary"}
                                            size={"lg"}
                                            variant={titlesInUppercase ? "solid" : "ghost"}
                                            onPress={() => d(setCv({ cv: produce(cv, d => { d.Metadata.titlesInUppercase = !titlesInUppercase }) }))}
                                        >
                                            <span className="material-symbols-outlined text-3xl">match_case</span>
                                        </Button>
                                        {titlesInUnderline && titlesInUppercase && <Divider orientation={"vertical"} />}
                                        <Button
                                            isIconOnly
                                            color={"primary"}
                                            size={"lg"}
                                            variant={titlesInUnderline ? "solid" : "ghost"}
                                            onPress={() => d(setCv({ cv: produce(cv, d => { d.Metadata.titlesInUnderline = !titlesInUnderline }) }))}
                                        >
                                            <span className="material-symbols-outlined text-3xl">
                                                format_underlined
                                            </span>
                                        </Button>
                                    </ButtonGroup>

                                    <Popover backdrop={"blur"} className={`${cs} text-foreground`}>
                                        <PopoverTrigger>
                                            <div className="w-24 self-stretch rounded-xl" style={{ backgroundColor: modelTitleColor || (modelBaseColor || colors.primary) }} />
                                        </PopoverTrigger>
                                        <PopoverContent className="p-0">
                                            <WebColorPickerView
                                                onChange={c => d(setCv({ cv: produce(cv, d => { d.Metadata.modelTitleColor = c }) }))}
                                                color={modelTitleColor || (modelBaseColor || colors.primary)}
                                            />
                                        </PopoverContent>
                                    </Popover>

                                </div>

                            </AccordionItem>
                        </Accordion>
                    </CardBody>
                </Card>


                {
                    _.some([
                        WorkExperience.length > 0, Education.length > 0, Achievement.length > 0,
                        Communication?.Description, Organisational?.Description, Computer?.Description, JobRelated?.Description, Other?.Description
                    ]) && (
                        <>
                            <WebListItemHeaderView text={t('advancedCustomizations')} />
                            <Card shadow={"none"}>
                                <CardBody className="p-2">

                                    <Accordion isCompact showDivider={false}>
                                        {
                                            WorkExperience.length > 0 && (
                                                <AccordionItem key="work" aria-label="Accordion 1" title={t("cvLabelExperience")} classNames={{ title: "text-small" }}>
                                                    <WebCommonTextView title={t("positionHeld")} k='workType' />
                                                    <WebCommonTextView title={t("employer")} k='company' />
                                                </AccordionItem>
                                            )
                                        }

                                        {
                                            Education.length > 0 && (
                                                <AccordionItem key="education" aria-label="Accordion 1" title={t("cvLabelEducation")} classNames={{ title: "text-small" }}>
                                                    <WebCommonTextView title={t("qualificationObtained")} k='qualification' />
                                                    <WebCommonTextView title={t("trainingInstitution")} k='institute' />
                                                </AccordionItem>
                                            )
                                        }

                                        {
                                            _.some([Communication?.Description, Organisational?.Description, Computer?.Description, JobRelated?.Description, Other?.Description]) && (
                                                <AccordionItem key="skill" aria-label="Accordion 1" title={t("cvLabelSkills")} classNames={{ title: "text-small" }}>
                                                    <WebCommonTextView title={t("title")} k='skill' />
                                                </AccordionItem>
                                            )
                                        }

                                        {
                                            Achievement.length > 0 && (
                                                <AccordionItem key="achievement" aria-label="Accordion 1" title={t("cvLabelAchievement")} classNames={{ title: "text-small" }}>
                                                    <WebCommonTextView title={t("title")} k='addInfo' />
                                                </AccordionItem>
                                            )
                                        }
                                    </Accordion>

                                </CardBody>
                            </Card>
                        </>
                    )
                }
            </div>

        </div>
    )
}

export default WebCVStyle
