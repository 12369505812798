import { useCallback, useState, useMemo, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Card, CardBody, Listbox, ListboxItem, CardHeader } from "@nextui-org/react";
import { produce } from "immer"
import { httpsCallable } from 'firebase/functions';

import { currencies } from "cvpop-collections-sdk"
import { illustrations } from 'cvpop-assets-sdk';
import { getJobSalaryMultiplier } from 'cvpop-utility-sdk';
import { setUser } from 'cvpop-redux-sdk'
import { t } from "cvpop-localization-sdk";

import _ from "lodash"

import { errorHandler, goToPaywall, openUrl } from '../../../controllers/app';
import { functions } from '../../../controllers/firebase';
import { AlertContext } from '../../../contexts/AlertContext';

import WebIcon from '../../atoms/WebIcon';
import WebLocationAutocomplete from '../../molecules/autocompletes/WebLocationAutocomplete';
import WebCenterView from '../../molecules/views/WebCenterView';

const WebSalary = () => {


    // ------------------------------------------------------------------------------------------------------
    const { user, userPro } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()
    const { dJobTitle, dJobLocation } = useLocation().state || {}
    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const n = useNavigate();
    const d = useDispatch();

    // ------------------------------------------------------------------------------------------------------
    const { professionalTitle, location } = user

    // ------------------------------------------------------------------------------------------------------
    const [jobTitle, setJobTitle] = useState(dJobTitle || professionalTitle || "")
    const [jobLocation, setJobLocation] = useState(dJobLocation || location || "")
    const [salary, setSalary] = useState((dJobTitle || dJobLocation) ? null : user.salary || null)
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, "getsalary"), [functions])
    const skipSave = useMemo(() => (dJobTitle || dJobLocation), [dJobTitle, dJobLocation])

    const graphData = useMemo(() => salary && {
        min: parseInt(_.meanBy(salary.salaryList.filter(e => e.min_salary), o => o.min_salary * getJobSalaryMultiplier(o.salary_period))),
        median: parseInt(_.meanBy(salary.salaryList.filter(e => e.median_salary), o => o.median_salary * getJobSalaryMultiplier(o.salary_period))),
        max: parseInt(_.meanBy(salary.salaryList.filter(e => e.max_salary), o => o.max_salary * getJobSalaryMultiplier(o.salary_period))),
        currency: (currencies[salary.salaryList[0].salary_currency] || "€")
    }, [salary])

    // ------------------------------------------------------------------------------------------------------
    const getSalary = useCallback(() => {
        setLoading(true)
        api({ jobTitle, jobLocation })
            .then(({ data }) => {
                if (data.data.length === 0) return alert(t("noSalaryData"), t("noSalaryDataMsg"))
                const salary = { jobTitle, jobLocation, salaryList: data.data }
                if (!skipSave) d(setUser({ user: produce(user, d => { d.salary = salary }) }))
                setSalary(salary)
            })
            .catch(e => errorHandler(alert, "err_get_salary", e))
            .finally(() => setLoading(false))
    }, [jobTitle, jobLocation, skipSave])

    const setUserJobTitle = useCallback(() => {
        d(setUser({ user: produce(user, d => { d.professionalTitle = jobTitle }) }))
        n(`/app/${platform}/onboarding/avatar`)
    }, [user, jobTitle, d, n])

    // ------------------------------------------------------------------------------------------------------
    if (!userPro) return (
        <div className="h-full flex items-center justify-center" style={{ minHeight: `calc(100vh - 4rem)` }}>
            <WebCenterView
                imageProps={{ src: illustrations.salary }}
                title={t("avgSalaryTitle")}
                message={t("avgSalaryMsg")}
            >
                <Button
                    color="primary"
                    className="mt-6"
                    onPress={() => goToPaywall(offering, platform, n)}
                    endContent={(
                        <div className='w-6 h-6 flex justify-center items-center shadow-2xl rounded-full'>
                            <WebIcon name={"workspace_premium"} className={"filled text-yellow-400"} />
                        </div>
                    )}
                >
                    {t("unlock")}
                </Button>

            </WebCenterView>
        </div>
    )
    if (salary) return (
        <div className='p-8'>
            <p className="font-sans font-bold text-3xl">{salary.jobTitle}</p>
            <p className="font-sans mt-2 mb-4 text-xl">{salary.jobLocation}</p>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-8">
                <Card>
                    <CardHeader className='text-xl font-bold'>{t("annualSalary")}</CardHeader>
                    <CardBody>
                        <div className='grid grid-cols-3 gap-4 items-end p-8'>
                            <div className=''>
                                <p className='text-xl text-center font-semibold'>{graphData.min}{graphData.currency}</p>
                                <div className='h-40 rounded-t-xl' style={{ backgroundColor: "#4aa3ff" }} />
                                <p className='text-lg text-center mt-2'>{t("min")}</p>
                            </div>
                            <div >
                                <p className='text-xl text-center font-semibold'>{graphData.median}{graphData.currency}</p>
                                <div className='h-48 rounded-t-xl' style={{ backgroundColor: "#006fe6" }} />
                                <p className='text-lg text-center mt-2'>{t("medium")}</p>
                            </div>
                            <div >
                                <p className='text-xl text-center font-semibold'>{graphData.max}{graphData.currency}</p>
                                <div className='h-56 rounded-t-xl' style={{ backgroundColor: "#003d82" }} />
                                <p className='text-lg text-center mt-2'>{t("max")}</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader className='text-xl font-bold'>{t("dataAggregatedBy")} {t("theseSources")}</CardHeader>
                    <CardBody>
                        <Listbox onAction={l => openUrl(l, platform)} className='p-0'>
                            {
                                salary.salaryList.map((e, i) => (
                                    <ListboxItem
                                        key={e.publisher_link}
                                        title={e.publisher_name}
                                        description={`${e.job_title}\n${e.location}`}
                                        endContent={<WebIcon name={"open_in_new"} />}
                                    />
                                ))
                            }
                        </Listbox>
                    </CardBody>
                </Card>
            </div>

            <div className='mt-8 text-center'>
                <Button color={"primary"} className="max-w-96" fullWidth onClick={() => setSalary(null)} style={{ margin: "0 auto" }}>
                    {t("newSearch")}
                </Button>
            </div>
        </div>
    )

    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "8rem" : "6rem"})` }}>
            <Card className='max-w-md m-3'>

                <CardBody>
                    <img src={illustrations.salary} className="max-w-80 self-center"/>

                    <p className="font-sans font-bold text-3xl mt-3">{t("avgSalaryTitle")}</p>
                    <p className="font-sans mt-3">{t("avgSalaryMsg")}</p>

                    <Input
                        defaultValue={jobTitle}
                        size={"lg"}
                        isClearable
                        autoFocus
                        autoCapitalize={"words"}
                        onChange={({ target }) => setJobTitle(target.value)}
                        placeholder={t("jobTitlePh")}
                        className='mt-3'
                    />

                    <WebLocationAutocomplete
                        onSelectionChange={t => setJobLocation(t)}
                    />

                    <Button
                        color="primary"
                        className='mt-3'
                        isLoading={loading}
                        isDisabled={!jobTitle || !jobLocation}
                        onClick={getSalary}
                    >
                        {t("continue")}
                    </Button>

                </CardBody>
            </Card>
        </div>
    )

}

export default WebSalary