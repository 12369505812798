import React, { useState, useCallback, useMemo, useRef, useEffect, useContext } from 'react';
import { Card, CardBody, Button } from "@nextui-org/react"
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { httpsCallable } from 'firebase/functions';
import { produce } from "immer"

import { countries } from 'cvpop-collections-sdk';
import { setUser} from 'cvpop-redux-sdk';
import { illustrations } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk";
import { isEligibleForCvPhotoUser } from 'cvpop-utility-sdk';

import _ from "lodash"

import { functions } from '../../../controllers/firebase';
import { errorHandler, getCountryCodeFromCity } from '../../../controllers/app';
import { AlertContext } from '../../../contexts/AlertContext';

import WebIcon from '../../atoms/WebIcon';


const WebIntroCommonOnlyImage = ({ type }) => {

    // ------------------------------------------------------------------------------------------------------
    const { user, userPro } = useSelector(state => state.userReducer)
    const { cvList } = useSelector(state => state.cvReducer)
    const { platform } = useParams();
    const { geolocation = null } = window.navigator
    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const d = useDispatch()
    const inputRef = useRef()

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)
    // const [showOffer, setShowOffer] = useState(true)

    // ------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, 'getlocationfromcoords'), []);
    const countryCode = useMemo(() => getCountryCodeFromCity(), []);
    const isEligibleForCvPhoto = useMemo(() => isEligibleForCvPhotoUser(countryCode), [countryCode]);

    // const onboardingStandardTrialIap = useMemo(() => iapProductList.find(e => e.identifier === rc.getString("onboardingStandardTrial")), [iapProductList])
    // const onboardingOfferTrialIap = useMemo(() => iapProductList.find(e => e.identifier === rc.getString("onboardingOfferTrial")), [iapProductList])

    // const api = useMemo(() => f.httpsCallable('getlocationfromcoords'), []);
    const defLocation = useMemo(() => (countries[user.language] || countries["en"]).find(e => e.code === countryCode)?.label || countryCode, [user.language, countryCode])

    const typeCamelCase = useMemo(() => _.startCase(_.toLower(type.replaceAll("-", " "))).replace(/ /g, ''), [type])

    const source = useMemo(() => {
        if (type === "new-profile") return illustrations.success
        if (type === "privacy") return illustrations.personaldata
        if (type === "more-info") return illustrations.welldone
        if (type === "import-cv") return illustrations.cv
        if (type === "feedback") return illustrations.review
        if (type === "completition") return illustrations.powerful
        return illustrations[type]
    }, [type])

    // ------------------------------------------------------------------------------------------------------
    const requestUserLocation = useCallback(async () => {

        const navToNext = e => {
            if (e) errorHandler(alert, "err_get_user_location", e, true)
            n(`/app/${platform}/onboarding/more-info`)
            setLoading(false)
        }

        const convertCoordsToLocation = coords => {
            api({ coords, language: user.language })
                .then(({ data }) => {
                    if (data.results.length === 0) return
                    const addressComponents = (data.results[0]?.address_components || [])
                    const city = addressComponents.find(i => i.types.includes("locality"))?.long_name || ""
                    const province = addressComponents.find(i => i.types.includes("administrative_area_level_2"))?.short_name || ""
                    const country = addressComponents.find(i => i.types.includes("country"))?.long_name || ""
                    const location = _.compact([city, province, country]).join(", ") || defLocation;
                    d(setUser({ user: produce(user, d => { d.location = location }) }))
                })
                .catch(e => navToNext(e)).finally(navToNext)
        }


        // start assigning default location
        d(setUser({ user: produce(user, d => { d.location = defLocation }) }))

        if (!geolocation) return navToNext()
        setLoading(true)
        geolocation.getCurrentPosition(({ coords }) => convertCoordsToLocation({ latitude: coords.latitude, longitude: coords.longitude }), e => navToNext(e))

    }, [platform, defLocation, geolocation, user, d, n, alert, api])

    const requestUserFeedback = useCallback(() => {
        window.webkit.messageHandlers["askForRate"].postMessage("appstore");
        n(`/app/${platform}/onboarding/${userPro ? "completition" : "paywall"}`, { state: { onboarding: true } })
    }, [n, platform, userPro])

    const importCv = useCallback(() => {
        if (!inputRef.current) return n(`/app/${platform}/onboarding/spinner`)
        inputRef.current.value = ""
        inputRef.current.click()
    }, [n, platform, inputRef])

    const completeOnboarding = useCallback(() => {
        d(setUser({
            user: produce(user, d => {
                d.isOnboarded = true
                d.lastOnboardOfferTime = new Date().getTime() + 30 * 60000;
            })
        }))

        n(`/app/${platform}/home/cv-list`)
    }, [d, n, platform, user])

    const onPress = useCallback(() => {
        if (type === "new-profile") return n(`/app/${platform}/onboarding/${isEligibleForCvPhoto ? "avatar" : "email"}`)
        if (type === "location") return requestUserLocation()
        if (type === "more-info") return n(`/app/${platform}/onboarding/seniority`)
        if (type === "import-cv") return importCv()
        if (type === "feedback") return requestUserFeedback()
        if (type === "completition") return completeOnboarding()
    }, [n, type, platform, isEligibleForCvPhoto, requestUserLocation, importCv, requestUserFeedback, completeOnboarding])

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (type === "import-cv" && cvList.length > 0) return n(`/app/${platform}/onboarding/features`)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (!showOffer || !isIapConfigReady || type !== "completition") return
    //     nav("offer", { trigger: userOfferTrialEligible ? "onboarding" : "periodic" })
    //     setShowOffer(false)
    // }, [type, isIapConfigReady, showOffer])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                {
                    platform !== "web" && (
                        <Button isIconOnly variant={"light"} className='m-3 mb-0' onClick={() => n(-1)}>
                            <WebIcon name={"west"} />
                        </Button>
                    )
                }
                <CardBody>
                    <div className="max-w-80 self-center">
                        <img alt={t("introLocationTitle")} src={source} />
                    </div>
                    <p className="font-sans font-bold text-2xl text-center mt-3">{t(`intro${typeCamelCase}Title`)}</p>
                    <p className="font-sans text-center mt-3">{t(`intro${typeCamelCase}Msg`)}</p>

                    <Button
                        color="primary"
                        className='mt-3'
                        onClick={onPress}
                        loading={loading}
                    >
                        {t(type === "import-cv" ? "import" : "continue")}
                    </Button>

                    {
                        type === "import-cv" && (
                            <Button className='mt-3' color='primary' variant={"light"} onClick={() => n(`/app/${platform}/onboarding/spinner`)}>
                                {t("notYet")}
                            </Button>
                        )
                    }

                    <input
                        type="file"
                        className='hidden'
                        accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ref={inputRef}
                        onChange={({ target }) => {
                            if (target.files.length === 0) return
                            if (!["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(target.files[0].type))
                                return alert(t("errTitle"), t("notSupportedTypeFile"))
                            n(`/app/${platform}/onboarding/spinner`, { state: { cvFile: target.files[0] } })
                        }}
                    />

                </CardBody>
            </Card>
        </div>
    )
};

export default WebIntroCommonOnlyImage