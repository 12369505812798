import React, { useCallback, useMemo, useState, useRef, useContext } from "react";
import { Button, Accordion, AccordionItem, Listbox, ListboxItem } from "@nextui-org/react";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom";
import { getBoolean } from "firebase/remote-config";
import { httpsCallable } from "firebase/functions";
import { produce } from "immer";

import { prewrittenPhraseCategoryCatalogue } from "cvpop-constants-sdk"
import { getEnglishLabelFromLanguageCode } from "cvpop-utility-sdk";
import { setCv, setSpin } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import ReactQuill from 'react-quill';
import _ from "lodash"

import { functions, remoteConfig } from "../../controllers/firebase";
import { errorHandler, goToPaywall } from "../../controllers/app";
import { useColorScheme } from "../../controllers/hooks";
import { AlertContext } from "../../contexts/AlertContext";

import WebIcon from "./WebIcon"

import 'react-quill/dist/quill.snow.css';


const WebEditor = ({ keyId = "", elType, qualification, value, onChange }) => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user, userPro } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()
    const { alert, prompt } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()
    const editorRef = useRef()
    const preWritterPhrasesModal = useDisclosure();
    const autoContentModal = useDisclosure();

    // ------------------------------------------------------------------------------------------------------
    const [autoContent, setAutoContent] = useState(null)

    // ------------------------------------------------------------------------------------------------------
    const prewrittenPhraseList = useMemo(() => Object.keys(prewrittenPhraseCategoryCatalogue), [])
    const api = useMemo(() => httpsCallable(functions, 'createtextcontent', { timeout: 120000 }), []);
    const aiOn = useMemo(() => (getBoolean(remoteConfig, "openAIOn") && elType !== "ACHIEVEMENT"), [elType])

    const emptyActions = useMemo(() => _.compact([
        aiOn && { id: `create${elType === "COVER_LETTER" ? "CoverLetter" : ""}`, title: t('createContent'), icon: userPro ? "stylus_note" : "workspace_premium", color: !userPro ? "text-yellow-400 filled" : "" },
        { id: "phrases", title: t('phrases'), icon: "add_notes" }
    ]), [aiOn, elType, user, userPro])

    const fillActions = useMemo(() => _.compact([
        aiOn && { id: `improve`, title: t('improve'), icon: userPro ? "stylus_note" : "workspace_premium", color: !userPro ? "text-yellow-400 filled" : "" },
        aiOn && { id: "addDetails", title: t("addDetails"), icon: userPro ? "subject" : "workspace_premium", color: !userPro ? "text-yellow-400 filled" : "" },
        aiOn && { id: "summarize", title: t("summarize"), icon: userPro ? "short_text" : "workspace_premium", color: !userPro ? "text-yellow-400 filled" : "" },
        aiOn && { id: "fixGrammar", title: t("fixGrammar"), icon: userPro ? "edit_note" : "workspace_premium", color: !userPro ? "text-yellow-400 filled" : "" },
        { id: "phrases", title: t('phrases'), icon: "add_notes" }
    ]), [aiOn, user, userPro])



    // ------------------------------------------------------------------------------------------------------
    const addTextToEditor = useCallback((text, mode = "add") => {
        if (!editorRef.current) return
        const editor = editorRef.current.getEditor()
        const unprivilegedEditor = editorRef.current.makeUnprivilegedEditor(editor);

        mode === "add" ?
            editor.insertText(unprivilegedEditor.getLength() - 1, text) :
            editor.setText(text)
    }, [editorRef])


    const onAction = useCallback(async action => {

        if (action === "phrases") return preWritterPhrasesModal.onOpen()
        if (!userPro) return goToPaywall(offering, platform, n)

        let jobTitle = cv.SkillsPassport.LearnerInfo.Identification.ProfessionalTitle;
        let q = qualification;

        if (["EDUCATION", "WORK"].includes(elType) && action.includes("create") && !q)
            return alert(t(`${elType === "WORK" ? "position" : "qualification"}NotPresentTitle`), t(`${elType === "WORK" ? "position" : "qualification"}NotPresentMsg`))

        if (!jobTitle && !["EDUCATION", "WORK"].includes(elType) && action.includes("create")) {
            try { jobTitle = (await prompt(t('jobTitleNotPresentTitle'), t('jobTitleNotPresentMsg')) || "").trim() } catch (e) { return }
            if (!jobTitle) return
            d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Identification.ProfessionalTitle = jobTitle }) }))
        }

        d(setSpin(true))

        let mappedElType = ""
        switch (elType) {
            case 'Summary': mappedElType = "SUMMARY"; break;
            case 'CoverLetter': mappedElType = "COVER_LETTER"; break;
            case 'Communication': mappedElType = "SKILL_COM"; break;
            case 'Organisational': mappedElType = "SKILL_ORG"; break;
            case 'JobRelated': mappedElType = "SKILL_PRO"; break;
            case 'Computer': mappedElType = "SKILL_DIG"; break;
            case 'Other': mappedElType = "SKILL_OTH"; break;
            default: mappedElType = elType
        }
        const editor = editorRef.current?.getEditor()
        api({ elType: mappedElType, jobTitle, qualification: q, targetLng: getEnglishLabelFromLanguageCode(cv.Metadata.lng), action, content: editor?.getText() || "" })
            .then(({ data }) => {
                setAutoContent({ action, content: data.result })
                autoContentModal.onOpen()
            })
            .catch(e => errorHandler(alert, "err_generate_ai_content", e))
            .finally(() => d(setSpin(false)))


    }, [cv, user, userPro, editorRef, qualification])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <div>
                <div className="flex">
                    <div id={`toolbar${keyId}`} className="w-full">
                        <button className="ql-bold"></button>
                        <button className="ql-italic mr-3"></button>
                        <button className="ql-list" value="ordered"></button>
                        <button className="ql-list mr-3" value="bullet"></button>
                        <button className="ql-clean"></button>
                    </div>
                    <Dropdown
                        backdrop={"blur"}
                        className={`${cs} text-foreground`}
                    >
                        <DropdownTrigger>
                            <Button color="primary" size={"sm"}>
                                {t("featAiEditor")}
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Dynamic Actions" color={"primary"} items={!!value ? fillActions : emptyActions} onAction={onAction}>
                            {({ id, icon, color, title }) => (
                                <DropdownItem key={id} title={title} endContent={<WebIcon name={icon} className={color} />} />
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <ReactQuill
                    ref={editorRef}
                    key={keyId}
                    className="bg-default-100 rounded-medium"
                    style={{ borderWidth: 0 }}
                    formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                    placeholder={t("writeHerePh")}
                    modules={{ toolbar: { container: `#toolbar${keyId}` } }}
                    value={value}
                    onChange={onChange}
                />

            </div>

            <Modal
                isOpen={preWritterPhrasesModal.isOpen}
                onOpenChange={preWritterPhrasesModal.onOpenChange}
                scrollBehavior={"inside"}
                backdrop={"blur"}
                className={`${cs} text-foreground`}
                hideCloseButton
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t("phrases")}</ModalHeader>
                            <ModalBody>
                                <Accordion isCompact>
                                    {
                                        _.orderBy(prewrittenPhraseList, e => t(`prewrittenPhraseCategory${e}`)).map((el, i) =>
                                            <AccordionItem key={`category${i}`} aria-label={t(`prewrittenPhraseCategory${el}`)} title={t(`prewrittenPhraseCategory${el}`)}>
                                                <Listbox
                                                    aria-label="Actions"
                                                    itemClasses={{ title: "whitespace-normal" }}
                                                >
                                                    {
                                                        _.map(_.times(userPro ? prewrittenPhraseCategoryCatalogue[el] : 2, _.constant(0)), (e, i) => t(`prewrittenPhrase${el}${i + 1}`)).map((s, j) => (
                                                            <ListboxItem
                                                                key={j}
                                                                variant={"flat"}
                                                                endContent={<WebIcon name={"add"} />}
                                                                title={s}
                                                                onClick={() => {
                                                                    addTextToEditor(s)
                                                                    onClose()
                                                                }}
                                                            />
                                                        ))
                                                    }
                                                    {
                                                        !userPro && (
                                                            <ListboxItem
                                                                key={"proSenteces"}
                                                                variant={"flat"}
                                                                endContent={<WebIcon name={"workspace_premium"} className={"text-yellow-400 filled"} />}
                                                                title={`+${prewrittenPhraseCategoryCatalogue[el] - 2} ${t("featPhrases")}`}
                                                                description={t("featPhrasesMsg")}
                                                                onClick={() => goToPaywall(offering, platform, n)}
                                                            />
                                                        )
                                                    }
                                                </Listbox>
                                            </AccordionItem>
                                        )
                                    }
                                </Accordion>

                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" variant="light" onPress={onClose}>
                                    {t("close")}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal
                isOpen={autoContentModal.isOpen}
                onOpenChange={autoContentModal.onOpenChange}
                scrollBehavior={"inside"}
                backdrop={"blur"}
                isDismissable={false}
                className={`${cs} text-foreground`}
                hideCloseButton
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1"></ModalHeader>
                            <ModalBody>
                                {autoContent.content}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    {t("delete")}
                                </Button>
                                <Button color="primary" onPress={() => {
                                    addTextToEditor(autoContent.content, (autoContent.action.includes("create") ? 'add' : 'replace'))
                                    onClose()
                                }}>
                                    {t(autoContent.action.includes("create") ? 'add' : 'replace')}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}

export default WebEditor