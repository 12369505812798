import { doc, deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";

import { db, storage } from './firebase'
import { errorHandler } from "./app"


export const deleteCv = async cv => {

    await deleteDoc(doc(db, "00_RESUMES_00", cv.Metadata.id))
    try {
        const hasVideo = cv.SkillsPassport.LearnerInfo.Video;
        if (hasVideo) await deleteObject(ref(storage, `videoResume/${cv.Metadata.uid}-${cv.Metadata.id}.mp4`))

        const attachments = cv.SkillsPassport.LearnerInfo.Attachment || []
        for (const f in attachments)
            await deleteObject(ref(storage, `attachments/${cv.Metadata.uid}/${cv.Metadata.id}/${attachments[f].filename}`))

    } catch (e) {
        errorHandler(null, "err_delete_cv_accessories", e, true)
    }
};