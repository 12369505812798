import { useCallback, useContext, useMemo, useState } from "react"
import { Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { httpsCallable } from "firebase/functions";
import { getBoolean } from "firebase/remote-config";

import { getCvSectionTextObject, getEnglishLabelFromLanguageCode, errorHandler } from "cvpop-utility-sdk";
import { t } from "cvpop-localization-sdk"

import Markdown from 'react-markdown'

import { functions, remoteConfig } from "../../../controllers/firebase";
import { goToPaywall } from "../../../controllers/app";
import { useColorScheme } from "../../../controllers/hooks";
import { AlertContext } from "../../../contexts/AlertContext";

import WebIcon from "../../atoms/WebIcon";



const WebAIAssistantButton = props => {

    // ------------------------------------------------------------------------------------------------------
    const { alert } = useContext(AlertContext)
    const { sectionId, color, item } = props

    // ------------------------------------------------------------------------------------------------------
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { offering } = useSelector(state => state.appReducer)
    const { cv } = useSelector(state => state.cvReducer)
    const { user, userPro } = useSelector(state => state.userReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const { language } = user

    // ------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const openAIOn = useMemo(() => getBoolean(remoteConfig, "openAIOn"), [remoteConfig])
    const api = useMemo(() => httpsCallable(functions, 'createcvaiassistance', { timeout: 90000 }), [functions]);

    const hLng = useMemo(() => getEnglishLabelFromLanguageCode(language), [language])
    const hasCap = useMemo(() => userPro || ["PERSONAL_DATA", "CONTACTS", "HEADLINE", "SIGNATURE", "ATTACHMENT", "VIDEO", "PORTFOLIO"].includes(sectionId), [user, userPro])

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")

    // ------------------------------------------------------------------------------------------------------
    const getAIAssistance = useCallback(() => {

        setLoading(true);
        const data = getCvSectionTextObject(cv, sectionId, language, item)
        api({ data, hLng, sectionId })
            .then(({ data }) => {
                setMessage(data)
                onOpen()
            })
            .catch(e => errorHandler(alert, "err_get_ai_assistance", e))
            .finally(() => setLoading(false))

    }, [user, cv, hLng])


    // ------------------------------------------------------------------------------------------------------
    if (!openAIOn) return null
    return (
        <>
            <Button
                fullWidth
                color={color || "primary"}
                isLoading={loading}
                endContent={(<WebIcon name={hasCap ? "smart_toy" : "workspace_premium"} className={`filled ${!hasCap && "text-yellow-400"}`} />)}
                onPress={() => hasCap ? getAIAssistance() : goToPaywall(offering, platform, n)}
                className="mt-4"
            >
                {t("aiAssistant")}
            </Button>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                hideCloseButton={true}
                size={"2xl"}
                scrollBehavior={"inside"}
                backdrop={"blur"}
                className={`${cs} text-foreground`}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t("aiAssistant")}</ModalHeader>
                            <ModalBody>
                                <Markdown>{message}</Markdown>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onPress={onClose}>
                                    {t("close")}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}

export default WebAIAssistantButton