module.exports = {
    styleMoscow: cv => {

        const { modelBaseColor = "#247BA0", modelTitleColor, modelExAreaColor, modelHobbyColor, modelNameColor = "black" } = cv.Metadata
        
        return `
            /* ----------------------------------------------- DOCUMENT */
            .left-column{
                background: ${modelBaseColor};
                color: white;
                padding: 40px 20px 10px 20px;
            }
            .right-column {
                padding-top: 40px;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                margin-bottom: 5px;
                display: block;
            }
            .section-title{
                color: white;
                padding: 5px;
                background-color: ${modelTitleColor || modelBaseColor};
                text-align: center;
            }
            .left-column > * .section-title{
                background-color: white;
                color: ${modelTitleColor || modelBaseColor};
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                display: block;
                background-color: transparent;
                margin-bottom: 20px;
            }
            .profile-pic{
                border: 3px solid white;
            }
            /* ----------------------------------------------- NAME */
            .name-container{
                padding-bottom: 20px;
            }
            .surname {
                color: ${modelNameColor};
                font-weight: bold;
            }
            /* ----------------------------------------------- CONTAC INFO */
            .right-column > * .contact-info-tag-icon {
                color: ${modelBaseColor};
            }
            .contact-info-tag-icon{
                color: white;
            }
            /* ----------------------------------------------- VIDEO */
            .left-column > .video-container.container > .video-content.content {
                text-align: center;
            }
            /* ----------------------------------------------- EXPERTISE AREA */
            ${((modelExAreaColor || modelBaseColor) === modelBaseColor) ? `
                .left-column > .container > .content > .expertise-area-text {
                    background: white;
                    color: black
                }
                ` : ""}
            /* ----------------------------------------------- HOBBY */
            ${((modelHobbyColor || modelBaseColor) === modelBaseColor) ? `
                .left-column > * .hobby-icon {
                    color: white
                }
                ` : ""}
        `

    }
}