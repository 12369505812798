import { useDispatch, useSelector } from "react-redux"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Card, CardBody, Button, Image, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, } from "@nextui-org/react"
import { doc, collection, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions"
import { getBoolean } from "firebase/remote-config"

import { maxCvLength } from "cvpop-constants-sdk";
import { createCvObject, dataToJson, getEnglishLabelFromLanguageCode, getJobSalary } from "cvpop-utility-sdk"
import { t } from "cvpop-localization-sdk"
import { setCv, setSpin } from "cvpop-redux-sdk"
import { icons } from "cvpop-assets-sdk"

import _ from "lodash"

import { auth, db, functions, remoteConfig } from "../../../../controllers/firebase"
import { createFbId, errorHandler, goToPaywall, openUrl } from "../../../../controllers/app"
import { useColorScheme } from "../../../../controllers/hooks"
import { AlertContext } from "../../../../contexts/AlertContext";

import WebSpinnerView from "../../../molecules/views/WebSpinnerView"
import WebErrorView from "../../../molecules/views/WebErrorView"
import WebIcon from "../../../atoms/WebIcon"


const WebJobDetail = ({ }) => {

    // ------------------------------------------------------------------------------------------------------------------------ 
    const { user, userPro } = useSelector(state => state.userReducer)
    const { cvList } = useSelector(state => state.cvReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { jobList } = useSelector(state => state.jobReducer)
    const { jobId, platform } = useParams()
    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const { language } = user

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(true)
    const [linkLoading, setLinkLoading] = useState(false)
    const [error, setError] = useState(false)
    const [job, setJob] = useState({})

    // ------------------------------------------------------------------------------------------------------------------------ 
    const isWeb = useMemo(() => platform === "web", [platform])

    const isSalaryOn = useMemo(() => getBoolean(remoteConfig, "salarySearch"), [remoteConfig])
    const isCvOn = useMemo(() => getBoolean(remoteConfig, "openAIOn"), [remoteConfig])

    const apiJob = useMemo(() => httpsCallable(functions, "getjobdetail"), [functions]);
    const cvApi = useMemo(() => httpsCallable(functions, 'createcvfromjob', { timeout: 120000 }), [functions]);

    const hLng = useMemo(() => getEnglishLabelFromLanguageCode(language), [language])
    const jobDraft = useMemo(() => _.find(jobList, j => (j.url).includes(jobId)), [jobList, jobId])
    const salary = useMemo(() => getJobSalary(jobDraft), [jobDraft])

    const actions = useMemo(() => (_.compact([
        isSalaryOn && { id: "salary", title: t("discoverAvgSalary"), icon: "payments" },
        isCvOn && { id: "cv", title: t("createSuiteableCv"), icon: userPro ? "note_add" : "workspace_premium", iconColor: userPro ? "" : `text-yellow-400 filled` },
        { id: "apply", title: t("applyNow"), icon: "open_in_new" },
    ])), [user, userPro, isCvOn, isSalaryOn])

    // ------------------------------------------------------------------------------------------------------
    const { phcompanylogo } = icons
    const { title, logoUrl, company, locations, description, fullDescription } = job

    // ------------------------------------------------------------------------------------------------------------------------ 
    const getJobDetail = useCallback(() => {
        setError(false)
        setLoading(true)
        apiJob({ job: jobDraft })
            .then(({ data }) => setJob(data))
            .catch(e => { errorHandler(alert, "err_get_job_detail", e, true); setError(true) })
            .finally(() => setLoading(false))
    }, [jobDraft])

    // ------------------------------------------------------------------------------------------------------
    const onAction = useCallback(id => {
        if (id === "salary") return n(`/app/${platform}/home/salary`, { state: { dJobTitle: title, dJobLocation: locations } })
        if (id === "apply") return openUrl(job.url, platform)

        if (id === "cv") {

            if (cvList.length >= maxCvLength) return alert(t("cvNumLimit"), t("cvNumLimitMsg"))
            if (!userPro) return goToPaywall(offering, platform, n)

            let newCv = null;

            d(setSpin(true))
            cvApi({ job, hLng })
                .then(({ data }) => {
                    const ref = doc(collection(db, "00_RESUMES_00"));
                    newCv = createCvObject(fbuser, user, ref.id, dataToJson(data, createFbId), "createdByJob", job);
                    return setDoc(doc(db, "00_RESUMES_00", ref.id), newCv)
                }).then(() => {
                    d(setCv({ cv: newCv, ignoreListener: true }))
                    n(`/app/${platform}/cv/edit`)
                })
                .catch(e => errorHandler(alert, "err_create_cv_from_job", e))
                .finally(() => d(setSpin(false)))

        }

    }, [user, userPro, cvList, job])


    // ------------------------------------------------------------------------------------------------------
    useEffect(() => { getJobDetail() }, [jobDraft])

    // ------------------------------------------------------------------------------------------------------------------------ 
    return (
        <div className="w-8/12" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>
            {
                error ? (
                    <div className="h-full flex items-center justify-center" style={{ minHeight: `calc(100vh - 4rem)` }}>
                        <WebErrorView buttonProps={{ onClick: () => getJobDetail() }} />
                    </div>
                ) : loading ? (
                    <WebSpinnerView />
                ) : (
                    <>
                        <div className="overflow-y-auto" style={{ height: `calc(100% - 104px)` }}>
                            <div className="p-8">
                                <p className="text-3xl font-bold">{title}</p>
                                <div className="flex mt-5 items-center">
                                    <div>
                                        <Image src={logoUrl || phcompanylogo} radius={"none"} fallbackSrc={phcompanylogo} className="max-w-48 max-h-16" />
                                    </div>
                                    <div className="ml-4">
                                        {company && <p className="text-lg">{company}</p>}
                                        {company && <p>{locations}</p>}
                                        {salary && <p>
                                            {
                                                salary.min === salary.max ?
                                                    `${salary.min} - ${salary.max}${salary.currency} / ${t(salary.period)}` :
                                                    `${salary.min}${salary.currency} / ${t(salary.period)}`
                                            }
                                        </p>
                                        }
                                    </div>
                                </div>

                                <Card shadow={"none"} className="mt-4">
                                    <CardBody>
                                        <div dangerouslySetInnerHTML={{ __html: fullDescription || description }} />
                                    </CardBody>
                                </Card>

                            </div>
                        </div>
                        <div className="p-8">
                            <Dropdown backdrop={"blur"} className={`${cs} text-foreground`}>
                                <DropdownTrigger>
                                    <Button color={"primary"} fullWidth isLoading={linkLoading} >
                                        {t("showOptions")}
                                    </Button>
                                </DropdownTrigger>
                                <DropdownMenu
                                    color={"primary"}
                                    aria-label="Static Actions"
                                    onAction={onAction}
                                >
                                    {actions.map(e => (
                                        <DropdownItem key={e.id} title={e.title} endContent={<WebIcon name={e.icon} className={e.iconColor} />} />
                                    ))}
                                </DropdownMenu>
                            </Dropdown>

                        </div>
                    </>
                )
            }
        </div>
    )
}

export default WebJobDetail