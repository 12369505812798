import React, { useCallback, useState, useMemo, useContext } from "react";
import { Accordion, AccordionItem, Button, Input, Switch, Select, SelectItem, ButtonGroup } from "@nextui-org/react";
import { useSelector, useDispatch } from 'react-redux'
import { produce } from "immer";

import { setCv } from "cvpop-redux-sdk";
import { getCvPickerItemLabel } from "cvpop-utility-sdk";
import { cvPickerItems } from "cvpop-constants-sdk";
import { t } from "cvpop-localization-sdk";
import { illustrations } from "cvpop-assets-sdk";

import _ from "lodash"

import { createFbId } from "../../../../controllers/app";
import { useColorScheme } from "../../../../controllers/hooks";
import { AlertContext } from "../../../../contexts/AlertContext";

import WebEditor from "../../../atoms/WebEditor";
import WebCenterView from "../../../molecules/views/WebCenterView";
import WebHeader from "../../../molecules/views/WebHeader";
import WebIcon from "../../../atoms/WebIcon";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";


const AchievementItem = ({ item, index }) => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { confirmDelete } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const { Id, Title, Description } = item

    // ------------------------------------------------------------------------------------------------------
    const [hasCustomTitle, setHasCustomTitle] = useState(!(!!cvPickerItems.addInfoTitle().find(o => o.code === item.Title.Code)))

    // ------------------------------------------------------------------------------------------------------
    const editCv = useCallback(cv => { d(setCv({ cv })) }, [cv])

    const deleteItem = useCallback(Id => {
        editCv(
            produce(cv, d => {
                const newList = d.SkillsPassport.LearnerInfo.Achievement.filter(t => t.Id !== Id)
                d.SkillsPassport.LearnerInfo.Achievement = newList
            })
        )
    }, [cv])

    // ------------------------------------------------------------------------------------------------------
    return (
        <>
            <div className="flex items-center">
                <div className="w-full">
                    {
                        hasCustomTitle ? (
                            <Input
                                label={t('customSectionTitle')}
                                defaultValue={Title.Label}
                                onChange={({ target }) => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Achievement[${index}].Title`, { Code: target.value, Label: target.value })))}
                                className="mt-3"
                            />
                        ) : (
                            <Select
                                label={t('customSectionTitle')}
                                defaultSelectedKeys={[Title.Code]}
                                textValue={t(getCvPickerItemLabel("addInfoTitle", Title.Code), { defaultValue: Title.Label })}
                                className="mt-3"
                                classNames={{ popoverContent: `${cs} text-foreground` }}
                                onChange={({ target }) => {
                                    const e = cvPickerItems.addInfoTitle().find(o => o.code === target.value)
                                    editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Achievement[${index}].Title`, { Code: e.code, Label: e.label })))
                                }}
                            >
                                {cvPickerItems.addInfoTitle().map(el => (<SelectItem key={el.code} value={el.code}>{t(el.label)}</SelectItem>))}
                            </Select>

                        )
                    }

                </div>
                <div className="ml-3">
                    <Switch
                        isSelected={hasCustomTitle}
                        onValueChange={s => {
                            setHasCustomTitle(s)
                            editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Achievement[${index}].Title`, { Code: "", Label: "" })))
                        }}
                    >
                        {t('useCustomSectionTitle')}
                    </Switch>
                </div>

            </div>

            <p className="text-xs text-default-600 font-bold uppercase ml-3 mb-1 mt-4">{t("description")}</p>
            <WebEditor
                elType={"ACHIEVEMENT"}
                keyId={`${Id}`}
                value={Description}
                onChange={t => editCv(produce(cv, d => void _.set(d, `SkillsPassport.LearnerInfo.Achievement[${index}].Description`, t === "<p><br></p>" ? "" : t)))}
            />

            <WebAIAssistantButton sectionId={"ADDITIONAL_INFO_ITEM"} item={item} />

            <Button
                fullWidth
                color={"danger"}
                className="my-4"
                onPress={() => confirmDelete().then(() => deleteItem(Id)).catch(() => { })}
            >
                {t("delete")}
            </Button>
        </>
    )

}


const WebAchievement = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------
    const [itemSelectedKeys, setItemSelectedKeys] = useState([]);

    // ------------------------------------------------------------------------------------------------------
    const itemList = useMemo(() => (cv.SkillsPassport.LearnerInfo.Achievement || []), [cv])

    // ------------------------------------------------------------------------------------------------------
    const addItem = useCallback(() => {

        const Id = createFbId()
        d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Achievement.push({ Id, Description: "", Title: { Code: "", Label: "" } }) }) }))
        setItemSelectedKeys([Id])

    }, [cv])

    const moveItem = useCallback((a, b) => {
        const copy = (cv.SkillsPassport.LearnerInfo.Achievement).slice();
        [copy[a], copy[b]] = [copy[b], copy[a]];
        d(setCv({ cv: produce(cv, d => { d.SkillsPassport.LearnerInfo.Achievement = copy }) }))
    }, [cv])


    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("cvLabelAchievement")} />
            <div className="p-5">
                {
                    itemList.length === 0 && (
                        <div className="items-center justify-center flex" style={{ height: `calc(100vh - 140px)` }}>
                            <WebCenterView
                                imageProps={{ src: illustrations.additionalinfo }}
                                title={t(`cvLabelAchievement`)}
                                message={t("emptyListMsg")}
                            >
                                <Button fullWidth color={"primary"} className="mt-6" onClick={addItem}>{t("add")}</Button>
                            </WebCenterView>
                        </div>
                    )
                }

                <Accordion variant="splitted" selectedKeys={itemSelectedKeys}>
                    {itemList.map((item, index) => (
                        <AccordionItem
                            isCompact
                            startContent={itemList.length > 1 && (
                                <ButtonGroup variant={"light"}>
                                    <Button isDisabled={index === 0} isIconOnly size={"sm"} onClick={() => moveItem(index, index - 1)}>
                                        <WebIcon name={"arrow_upward"} className={"text-base"} />
                                    </Button>
                                    <Button isDisabled={index == itemList.length - 1} isIconOnly size={"sm"} onClick={() => moveItem(index, index + 1)}>
                                        <WebIcon name={"arrow_downward"} className={"text-base"} />
                                    </Button>
                                </ButtonGroup>
                            )}
                            title={item.Title.Code ?
                                t((cvPickerItems.addInfoTitle().find(e => e.code === item.Title.Code) || { label: item.Title.Label || "elementWithNoTitle" }).label) :
                                t("elementWithNoTitle")}
                            onPress={() => setItemSelectedKeys((itemSelectedKeys[0] || "") === item.Id ? [] : [item.Id])}
                            key={item.Id}
                        >
                            <AchievementItem item={item} index={index} />
                        </AccordionItem>
                    ))}
                </Accordion>

                {
                    itemList.length > 0 && (
                        <Button
                            fullWidth
                            color={"primary"}
                            variant={"ghost"}
                            className="mt-3"
                            onPress={addItem}
                        >
                            {t("addNewItem")}
                        </Button>
                    )
                }
            </div>

        </div >
    )
}

export default WebAchievement