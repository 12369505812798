module.exports = {
    styleSydney: cv => {

        const { modelBaseColor = "#247BA0" } = cv.Metadata
        
        return `
            /* ----------------------------------------------- DOCUMENT */
            .header-pic {
                background-color: ${modelBaseColor};
            }
            .body{
                padding: 15px 40px 0px 40px;
            }
            .container{
                display: table;
            }	
            .content{
                min-width: 490px;
                max-width: 490px;
            }
            /* ----------------------------------------------- HEADER */
            .header{
                padding: 0px;
            }
            /* ----------------------------------------------- TITLES */
            .section-title-container{
                display: table-cell;
                width: 170px;
                text-align: right;
                padding-top: 5px;
                padding-right: 20px;
                vertical-align: top;
            }
            /* ----------------------------------------------- PROFILE PICTURE */
            .profile-pic-container{
                padding-top: 40px;
                text-align: center;
                height: 115px;
                display: block;
                background-color: transparent;
            }
            .profile-pic {
                border: 3px solid white;
            }
            /* ----------------------------------------------- NAME */
            .name-container{
                margin-top: 30px;
                display: block;
                background-color: transparent;
                padding: 40px 20px 0px 20px;
                width: unset;
            }
            .name {
                text-align: center;
            }
            .professional-title{
                text-align: center;
            }
            /* ----------------------------------------------- CONTAC INFO */
            .contact-info-tag-icon{
                color: black;
            }
            /* ----------------------------------------------- PORTFOLIO */
            .portfolio-item {
                height: 160px;
                width: 160px;
                margin-right: 0;
            }
            /* ----------------------------------------------- ATTACHMENT */
            .attachment-item {
                height: 115px;
                width: 115px;
            }
            /* ----------------------------------------------- HOBBY */
            .hobby-item {
                display: inline-table;
                width: 32%;
            }
            .hobby-icon-container{
                width: 30%;
            }
        `

    }
}