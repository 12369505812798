import { useState, useCallback, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "@nextui-org/react";
import { doc, collection, setDoc } from "firebase/firestore";

import { maxCvLength } from "cvpop-constants-sdk";
import { duplicateCvObject } from "cvpop-utility-sdk"
import { t } from "cvpop-localization-sdk"
import { setCv } from "cvpop-redux-sdk";

import _ from "lodash"

import { db } from "../../../controllers/firebase";
import { errorHandler, goToPaywall } from "../../../controllers/app";
import { AlertContext } from "../../../contexts/AlertContext";


const WebDuplicateCVButton = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv, cvList } = useSelector(state => state.cvReducer)
    const { userPro } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()
    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------------------------
    const runDuplicate = useCallback(() => {

        if (cvList.length >= maxCvLength) return alert(t("cvNumLimit"), t("cvNumLimitMsg"));

        const ref = doc(collection(db, "00_RESUMES_00"));
        const newCv = duplicateCvObject(cv, ref.id, t("copy"))
        setLoading(true)
        setDoc(doc(db, "00_RESUMES_00", ref.id), newCv)
            .then(() => {
                d(setCv({ cv: newCv, ignoreListener: true }))
                alert(t("cvDuplicated"), t("cvDuplicatedMsg"))
            })
            .catch(e => errorHandler(alert, "err_duplicate_cv", e))
            .finally(() => setLoading(false))

    }, [cv, cvList])


    // ------------------------------------------------------------------------------------------------------------------------ 
    return loading ? (
        <div className="flex flex-col items-center justify-center min-h-10">
            <Spinner size={"sm"} />
        </div>
    ) : (
        <div className="flex flex-col items-center" onClick={() => userPro ? runDuplicate() : goToPaywall(offering, platform, n)}>
            <span className=" material-symbols-outlined">file_copy</span>
            <span className="text-xs">{t("duplicate")}</span>
            {!userPro && (<span className="text-yellow-500" style={{ fontSize: "0.6rem" }}>({t("premium")})</span>)}
        </div>
    )
}

export default WebDuplicateCVButton