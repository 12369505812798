import { useCallback, useState, useMemo, useRef, useContext } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Input, Button, Spinner, Image } from "@nextui-org/react";

import { t } from "cvpop-localization-sdk";

import _ from "lodash"

import { useColorScheme } from "../../../controllers/hooks";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../controllers/firebase";
import { errorHandler, imageToBase64 } from "../../../controllers/app";
import { AlertContext } from "../../../contexts/AlertContext";


const WebImageSearchModal = ({ modal, onImageSelected }) => {

    // ------------------------------------------------------------------------------------------------------
    const { alert } = useContext(AlertContext)
    const { isOpen, onOpenChange, onClose } = modal

    // ------------------------------------------------------------------------------------------------------
    const cs = useColorScheme()
    const inputRef = useRef()

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [imageViewSource, setImageViewSource] = useState([]);

    // ------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, 'getimagelist'), []);


    // ------------------------------------------------------------------------------------------------------
    const searchImage = useCallback(_.debounce(q => {
        if (!q || q.length < 3) return
        setLoading(true)
        api({ q })
            .then(({ data }) => setImageViewSource(data.value.map((image, index) => ({ uri: image.thumbnailUrl, name: image.name || "", index }))))
            .catch(e => errorHandler(alert, "err_search_image", e))
            .finally(() => setLoading(false))
    }, 1000), [api])


    const onImageClick = useCallback((image, index) => {
        setLoadingImage(index)
        fetch(image.uri)
            .then(img => img.blob())
            .then(blob => imageToBase64(blob))
            .then(base64 => onImageSelected(base64))
            .catch(e => errorHandler(alert, "err_download_web_image", e))
            .finally(() => setLoadingImage(false))
    }, [onImageSelected])

    const onModalClose = useCallback(() => {
        setLoading(false)
        setImageViewSource([])
        if (inputRef.current) inputRef.current.value = ""
    }, [inputRef])


    // ------------------------------------------------------------------------------------------------------
    return (
        <Modal
            isOpen={isOpen}
            onModalClose={onModalClose}

            onOpenChange={onOpenChange}
            hideCloseButton={true}
            backdrop={"blur"}
            scrollBehavior={"inside"}
            size={"4xl"}
            className={`${cs} text-foreground`}
        >
            <ModalContent>
                <ModalHeader className="flex flex-col gap-1">
                    {t("searchImage")}
                    <Input
                        autoFocus
                        ref={inputRef}
                        placeholder={t("writeHerePh")}
                        onChange={({ target }) => searchImage(target.value)}
                        endContent={loading && <Spinner size={"sm"} />}
                    />
                </ModalHeader>
                <ModalBody>
                    <div class="grid grid-cols-3 gap-4 items-center">
                        {
                            imageViewSource.map((item, index) => (
                                <Image
                                    key={`image${index}`}
                                    loading={loadingImage === index}
                                    src={item.uri}
                                    onClick={() => onImageClick(item, index)}
                                    classNames={{ wrapper: "self-center justify-self-center cursor-pointer" }}
                                />
                            ))
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color={"primary"} onPress={onClose}>
                        {t("close")}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )

}

export default WebImageSearchModal