import { useCallback, useState, useMemo, useContext } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Button, Image, Input } from '@nextui-org/react';
import { EmailAuthProvider, sendPasswordResetEmail } from "firebase/auth";
import { signInWithEmailAndPassword, signInWithCredential, linkWithCredential, reauthenticateWithCredential, sendEmailVerification } from "firebase/auth";
import { produce } from "immer"

import { setUser } from "cvpop-redux-sdk";
import { illustrations } from "cvpop-assets-sdk";
import { regex } from "cvpop-constants-sdk";
import { t } from "cvpop-localization-sdk"

import { auth } from "../../controllers/firebase";
import { postLogin } from '../../controllers/user';
import { errorHandler } from '../../controllers/app';
import { AlertContext } from '../../contexts/AlertContext';

import WebIcon from '../atoms/WebIcon';
import WebNavbar from '../atoms/WebNavbar';


const WebSign = ({ action, event, onboarding }) => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { language } = useSelector(state => state.webReducer)
    const { platform } = useParams()
    const { alert, prompt } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate();
    const d = useDispatch()
    const fbuser = auth.currentUser;

    // ------------------------------------------------------------------------------------------------------------------------
    const [email, setEmail] = useState(fbuser?.email || (user?.email || ""));
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------------------------
    const isWeb = useMemo(() => platform === "web", [platform])
    const isPwdValid = useMemo(() => (password !== "" && password.length >= 6), [password])
    const isEmailValid = useMemo(() => (email !== "" && email.toLowerCase().match(regex.mail)), [email])
    const isFormValid = useMemo(() => (isEmailValid && isPwdValid), [isEmailValid, isPwdValid])

    // ------------------------------------------------------------------------------------------------------------------------
    const runSignIn = useCallback(async mode => {

        setLoading(true)
        try {
            const cred = EmailAuthProvider.credential(email, password);

            if (action === 'login') {
                const userCred = mode === "email" ? await signInWithEmailAndPassword(auth, email, password) : await signInWithCredential(auth, cred);
                const data = await postLogin({ user: userCred.user, language, platform })
                d(setUser({ user: data, ignoreListener: true }));
                n(`/app/${platform}/home/cv-list`)
            }

            if (action === 'convert') {
                await linkWithCredential(auth.currentUser, cred)
                d(setUser({ user: produce(user, d => { d.isAnonymous = false; d.email = email }) }));
                sendEmailVerification(auth.currentUser)
                    // .then(() => cr.log(`[sendVerificationEmail] Verification email sent successfully`))
                    .catch(e => errorHandler(alert, "err_send_email_verification", e, true))
                onboarding ? n(`/app/${platform}/home/cv-list`) : n(-1)
            }

            if (action === "reauth") {
                await reauthenticateWithCredential(auth.currentUser, cred)
                n(`/app/${platform}/home/account`, { state: { [event === "deleteUserEv" ? "runDeleteAccount" : "runUpdateEmail"]: true } })
            }

            setLoading(false)
        } catch (e) {

            setLoading(false)
            // if (e.code === "1001" || e.code === statusCodes.SIGN_IN_CANCELLED) return
            errorHandler(alert, `err_${mode}_sign`, e)
        }

    }, [user, email, password, language, action, onboarding, d, n])

    const runForgotPwd = useCallback(() => {

        setLoading(true)
        prompt(t('forgotPwdTitle'), t('forgotPwdMsg'), null, null, null, email)
            .then(value => {
                if (!value) throw { code: "global/cancel-action" }
                return sendPasswordResetEmail(auth, value)
            })
            .then(() => alert(t('forgotPwdSentTitle'), t('forgotPwdSentMsg')))
            .catch(e => errorHandler(alert, "err_forgot_pwd", e))
            .finally(() => setLoading(false))

    }, [email]);

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebNavbar onboarding={true} />

            <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${isWeb ? "4rem" : "0px"})` }}>
                <Card className='w-96 my-3'>
                    <CardBody>

                        {
                            !isWeb && (
                                <Button isIconOnly variant={"light"} onClick={() => n(-1)}>
                                    <WebIcon name={"west"} />
                                </Button>
                            )
                        }

                        <div className="max-w-80 self-center">
                            <Image src={illustrations[action]} className="max-w-xs self-center" />
                        </div>

                        <p className="font-sans font-bold text-xl text-center mt-0 m-3">
                            {t(
                                action === "convert" ? "createAccount" :
                                    action === "reauth" ? event === "deleteUserEv" ? "reauthMsgDeleteAccount" : "reauthMsgUpdateEmail" :
                                        "login"
                            )}
                        </p>

                        <Input type="email" label={t('email')} defaultValue={email} onChange={({ target }) => setEmail(target.value)} />
                        <Input
                            type="password"
                            label={t('pwd')}
                            className='mt-3'
                            onChange={({ target }) => setPassword(target.value)}
                            onKeyDown={({ key }) => (key === "Enter" && isFormValid) && runSignIn("email")}
                        />
                        <Button
                            color="primary"
                            className='mt-3'
                            isLoading={loading}
                            isDisabled={!isFormValid}
                            onPress={() => runSignIn("email")}
                        >
                            {t(action === 'convert' ? 'createAccount' : 'login')}
                        </Button>

                        {
                            onboarding && action === "convert" && (
                                <Button color="primary" className='mt-3' variant={"light"} onClick={() => n(`/app/${platform}/home/cv-list`)} >
                                    {t("skip")}
                                </Button>
                            )
                        }

                        {
                            action === 'login' || action === "reauth" && (
                                <Button color="primary" variant={"light"} className='mt-3' isDisabled={loading} onClick={runForgotPwd}>
                                    {t("forgotPwdQuestion")}
                                </Button>
                            )
                        }

                    </CardBody>

                </Card>
            </div>
        </div>
    )
}

export default WebSign