import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { connectFunctionsEmulator } from 'firebase/functions';
import { logEvent } from "firebase/analytics";
import { Spinner } from '@nextui-org/react';

import { isAmsUser, isProUser } from 'cvpop-utility-sdk';
import { setUserAms, setUserPro } from 'cvpop-redux-sdk';

import { useColorScheme } from '../../controllers/hooks';
import { setAppLocalization } from "../../controllers/app";
import { functions, analytics } from '../../controllers/firebase';

import WebLanding from "./WebLanding";


const WebRoot = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { language } = useSelector(state => state.webReducer)
    const { platform } = useParams()
    const { pathname, search } = useLocation()

    // ------------------------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(true)

    // ------------------------------------------------------------------------------------------------------------------------
    const cs = useColorScheme()
    const d = useDispatch( )

    // ------------------------------------------------------------------------------------------------------------------------
    const setupUserInfo = useCallback(() => {
        const isPro = isProUser(user)
        const isAms = isAmsUser(user)
        
        d(setUserPro(isPro))
        d(setUserAms(isAms))
    }, [user])

    // ------------------------------------------------------------------------------------------------------------------------
    useEffect(() => void setupUserInfo(), [setupUserInfo])
    useEffect(() => {
        setAppLocalization(language || "en")
        setLoading(false)
    }, [language])

    useEffect(() => {
        document.body.className = `${cs} text-foreground bg-background overscroll-none`;
    }, [cs])

    useEffect(() => {
        logEvent(analytics, `screen_view_${platform}`, { firebase_screen: `${pathname}${search}`, firebase_screen_class: `${pathname}${search}` });
    }, [pathname, search]);

    useEffect(() => {
        if (window?.location?.hostname === "localhost")
            connectFunctionsEmulator(functions, "192.168.1.84", 5001);
    }, [])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        loading ? (
            <div className='h-screen w-screen flex items-center justify-center'>
                <Spinner />
            </div>
        ) : (
            pathname === "/" ? <WebLanding /> : <Outlet />
        )
    )

}

export default WebRoot