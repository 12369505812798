import { useState, useCallback, useMemo, useContext } from "react"
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Spinner } from "@nextui-org/react";
import { collapseWhiteSpace } from "collapse-white-space"
import { httpsCallable } from "firebase/functions";
import { getBoolean } from "firebase/remote-config";

import { t } from "cvpop-localization-sdk"
import { renderCvPreview } from "cvpop-utility-sdk";

import { functions, remoteConfig } from "../../../controllers/firebase";
import { base64ToBlob, downloadBlob, errorHandler, goToPaywall } from "../../../controllers/app";
import { useColorScheme } from "../../../controllers/hooks";
import { AlertContext } from "../../../contexts/AlertContext";

import WebIcon from "../../atoms/WebIcon";


const WebDownloadCVButton = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user, userPro } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()
    const { alert } = useContext(AlertContext)

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, 'getcvv2'), []);
    const fOn = useMemo(() => (getBoolean(remoteConfig, "docxExport")), [])

    // ------------------------------------------------------------------------------------------------------
    const downloadCv = useCallback(async i => {

        if (i === "getDocx" && !userPro) return goToPaywall(offering, platform, n)

        try {
            setLoading(true)

            const { id, pdfSize } = cv.Metadata
            const { html, footerTemplate } = renderCvPreview(cv, user);

            const cHtml = collapseWhiteSpace(html, { style: 'html', preserveLineEndings: false })
            const compression = userPro ? (pdfSize || "STANDARD") : "STANDARD";
            const type = i === "getInfographic" ? "jpeg" : i === "getDocx" ? "docx" : "pdf"
            const contentType = i === "getInfographic" ? "image/jpeg" : `application/${type === "pdf" ? "pdf" : "vnd.openxmlformats-officedocument.wordprocessingml.document"}`

            const { data } = await api({ html: cHtml, footerTemplate, type, compression, cvId: id, cv }) // this is a base64

            if (platform === "macos" || platform === "visionos")
                window.webkit.messageHandlers[`${type}Download`].postMessage(data);

            if (platform === "web") {
                const blob = base64ToBlob(data, contentType);
                downloadBlob(blob, `CV.${type}`);
            }

            setLoading(false)

        } catch (e) {
            errorHandler(alert, "err_download_cv", e);
            setLoading(false)
        }

    }, [user, userPro, cv])

    // ------------------------------------------------------------------------------------------------------
    return loading ? (
        <div className="flex flex-col items-center justify-center min-h-10">
            <Spinner size={"sm"} />
        </div>
    ) : (
        <Dropdown
            backdrop={"blur"}
            placement="right"
            className={`${cs} text-foreground`}
        >
            <DropdownTrigger>
                <div className="flex flex-col items-center">
                    <span className="material-symbols-outlined">download</span>
                    <span className="text-xs">{t("download")}</span>
                </div>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions" color={"primary"} onAction={k => downloadCv(k)}>
                <DropdownItem
                    key="download"
                    title={t("download")}
                    endContent={<WebIcon name={"download"} />}
                />
                <DropdownItem
                    key="getInfographic"
                    title={t("getInfographic")}
                    endContent={<WebIcon name={"planner_banner_ad_pt"} />}
                />
                {fOn && (
                    <DropdownItem
                        key="getDocx"
                        title={t("getDocx")}
                        endContent={<WebIcon name={userPro ? "edit_document" : "workspace_premium"} className={!userPro && `text-yellow-400 filled`} />}
                    />
                )}

            </DropdownMenu>
        </Dropdown>
    )
}

export default WebDownloadCVButton