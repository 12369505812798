import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button, Card, CardBody } from "@nextui-org/react";
import { httpsCallable } from "firebase/functions";
import { Helmet } from "react-helmet";

import { illustrations, logos } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk";

import { useColorScheme } from "../../controllers/hooks"
import { functions } from "../../controllers/firebase";
import { errorHandler } from "../../controllers/app";
import { AlertContext } from "../../contexts/AlertContext";

import WebSpinnerView from "../molecules/views/WebSpinnerView";
import WebErrorView from "../molecules/views/WebErrorView";
import WebNavbar from "../atoms/WebNavbar";


const WebPublicCV = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { alert } = useContext(AlertContext)
    const { publicLinkId } = useParams() // not-found, expired, success, error

    // ------------------------------------------------------------------------------------------------------------------------
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [cvInfo, setCvInfo] = useState({})

    // ------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, 'getcvfrompublicid'), []);

    // ------------------------------------------------------------------------------------------------------------------------
    const getPublicCv = useCallback(() => {

        setLoading(true);
        setError(false);
        api({ publicLinkId })
            .then(({ data }) => data.status === "success" ? setCvInfo(data) : setError(data.code))
            .catch(e => {
                errorHandler(alert, "err_get_public_cv", e, true)
                setError(true)
            })
            .finally(() => setLoading(false))
    }, [publicLinkId])

    // ------------------------------------------------------------------------------------------------------------------------
    useEffect(() => { getPublicCv() }, [])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div className={`landing ${cs}`}>
            <WebNavbar  navbarProps={{ isBordered: false, maxWidth: "lg", className: "" }} />
            <div className="container mx-auto max-w-[1024px] px-6" style={{ minHeight: `calc(100vh - 4rem)` }}>

                {
                    error ? (
                        <div className="h-full flex items-center justify-center" style={{ minHeight: `calc(100vh - 4rem)` }}>
                            {
                                error === "cvnotfound" || error === "usernotfound" ? (
                                    <Card className='max-w-md m-3 mb-0'>
                                        <CardBody>
                                            <div className="max-w-80 self-center">
                                                <img alt={t("introAvatarTitle")} src={illustrations.notfound} className={`rounded-xl`} />
                                            </div>
                                            <p className="font-sans font-bold text-2xl text-center mt-3">{t(`publicCvNotFoundMsgTitle`)}</p>
                                            <p className="font-sans text-center mt-3">{t(`publicCvNotFoundMsg`)}</p>
                                        </CardBody>
                                    </Card>
                                ) : (
                                    <WebErrorView buttonProps={{ onClick: () => getPublicCv() }} />
                                )
                            }
                        </div>
                    ) : loading ? (
                        <WebSpinnerView />
                    ) : (
                        <div>
                            <Helmet>
                                {cvInfo.name && <title>CV {cvInfo.name}</title>}
                                <meta name="viewport" content="minimal-ui" />
                            </Helmet>
                            <div className="my-24">
                                <div className="flex items-center" style={{ width: 760, margin: "0 auto" }}>
                                    <div className="w-full">
                                        <p className="text-5xl font-bold">{t("hello")}{cvInfo.name && ","}</p>
                                        <p className="text-5xl font-bold mt-6">{cvInfo.name ? t("iAmName", { n: cvInfo.name }) : t("thisIsMyCv")}. </p>
                                    </div>
                                    {
                                        cvInfo.email && (
                                            <div>
                                                <Button color={"primary"} as={Link} href={`mailto:${cvInfo.email}`} target='_'>
                                                    {t("contactMe")}
                                                </Button>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: cvInfo.html }} className="pb-8" />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default WebPublicCV