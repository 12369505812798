module.exports = {

    maxCvLength: 10,

    defaultCvObject: Object.freeze({
        SkillsPassport: {
            DocumentInfo: { EuropassLogo: true },
            LearnerInfo: {
                Identification: {
                    PersonName: { FirstName: "", Surname: "" },
                    ProfessionalTitle: "",
                    Photo: { MimeType: "", Data: "", Metadata: [] },
                    ContactInfo: {
                        Address: { Contact: { AddressLine: "", PostalCode: "", Municipality: "", Country: { Code: "", Label: "" } } },
                        Email: { Contact: "" },
                        Telephone: [],
                        InstantMessaging: [],
                        Website: [],
                        SocialAccount: []
                    },
                    Demographics: {
                        Birthdate: { Year: null, Month: null, Day: null },
                        Gender: { Code: "", Label: "" },
                        Nationality: []
                    },
                    Summary: { Description: "" },
                    CoverLetter: { Description: "" }
                },
                WorkExperience: [],
                Education: [],
                Headline: { Type: { Code: "", Label: "" }, Description: { Label: "" } },
                Skills: { Linguistic: { MotherTongue: [], ForeignLanguage: [] } },
                Achievement: [],
                Attachment: [],
                Portfolio: [],
                ExpertiseArea: [],
                Ability: [],
                DriverLicenceSpecial: []
            }
        }
    }),

    defaultModelLayout: {
        "left_column": {
            "left": ["contact-info", "languages", "foreign-languages", "driver-licences", "driver-licences-special", "expertise-areas", "abilities", "hobbies"],
            "right": ["video", "summary", "headline", "work", "education", "portfolio", "skills", "achievements", "attachments"]
        },
        "single_column": {
            "center": ["contact-info", "languages", "video", "summary", "headline", "expertise-areas", "work", "education", "portfolio", "foreign-languages", "driver-licences", "driver-licences-special", "abilities", "skills", "hobbies", "achievements", "attachments"]
        },
        "half_single_column": {
            "left": ["contact-info"],
            "right": ["languages", "driver-licences", "driver-licences-special"],
            "center": ["video", "summary", "headline", "expertise-areas", "work", "education", "portfolio", "foreign-languages", "abilities", "skills", "hobbies", "achievements", "attachments"]
        }
    },

    modelCatalogue: [{
        code: "london",
        displayName: "mLondon",
        capabilities: ["photo_format", "name_color", "photo_center"],
        defaultPattern: "0",
        defaultImage: "0",
        layout: "left_column",
        category: "classic",
        isFree: true
    }, {
        code: "rio",
        displayName: "mRio",
        capabilities: ["photo_format", "name_color", "photo_center"],
        defaultPattern: "0",
        defaultImage: "0",
        layout: "half_single_column",
        category: "classic",
        isFree: true
    }, {
        code: "newyork",
        displayName: "mNewyork",
        capabilities: ["photo_format", "bg_pattern", "photo_center"],
        defaultPattern: "2",
        defaultImage: "0",
        layout: "single_column",
        category: "creative",
        isFree: true
    }, {
        code: "tokyo",
        displayName: "mTokyo",
        capabilities: ["photo_format", "photo_center"],
        defaultPattern: "0",
        defaultImage: "0",
        layout: "left_column",
        category: "simple",
        isFree: false
    }, {
        code: "helsinki",
        displayName: "mHelsinki",
        capabilities: ["photo_format", "name_color", "photo_center"],
        defaultPattern: "0",
        defaultImage: "0",
        layout: "half_single_column",
        category: "classic",
        isFree: false
    }, {
        code: "shanghai",
        displayName: "mShanghai",
        capabilities: ["photo_format", "bg_pattern", "photo_center"],
        defaultPattern: "7",
        defaultImage: "0",
        layout: "left_column",
        category: "creative",
        isFree: false
    }, {
        code: "sydney",
        displayName: "mSydney",
        capabilities: ["photo_format", "name_color", "bg_pattern", "photo_center"],
        defaultPattern: "4",
        defaultImage: "0",
        layout: "single_column",
        category: "creative",
        isFree: false
    }, {
        code: "lisbon",
        displayName: "mLisbon",
        capabilities: ["bg_image", "photo_format", "photo_center"],
        defaultPattern: "0",
        defaultImage: "5",
        layout: "left_column",
        category: "dynamic",
        isFree: false
    }, {
        code: "paris",
        displayName: "mParis",
        capabilities: ["photo_format", "bg_pattern", "photo_center"],
        defaultPattern: "8",
        defaultImage: "0",
        layout: "half_single_column",
        category: "creative",
        isFree: false
    }, {
        code: "milan",
        displayName: "mMilan",
        capabilities: ["bg_pattern"],
        defaultPattern: "10",
        defaultImage: "0",
        layout: "single_column",
        category: "creative",
        isFree: false
    }, {
        code: "moscow",
        displayName: "mMoscow",
        capabilities: ["photo_format", "name_color", "photo_center"],
        defaultPattern: "0",
        defaultImage: "0",
        layout: "left_column",
        category: "simple",
        isFree: false
    }, {
        code: "atene",
        displayName: "mAtene",
        capabilities: ["name_color"],
        defaultPattern: "0",
        defaultImage: "0",
        layout: "left_column",
        category: "simple",
        isFree: false
    }, {
        code: "madrid",
        displayName: "mMadrid",
        capabilities: ["photo_format", "name_color", "bg_pattern", "photo_center"],
        defaultPattern: "3",
        defaultImage: "0",
        layout: "single_column",
        category: "creative",
        isFree: false
    }, {
        code: "amsterdam",
        displayName: "mAmsterdam",
        capabilities: ["bg_pattern", "photo_center"],
        defaultPattern: "1",
        defaultImage: "0",
        layout: "left_column",
        category: "simple",
        isFree: false
    }, {
        code: "istanbul",
        displayName: "mIstanbul",
        capabilities: ["photo_format", "photo_center"],
        defaultPattern: "0",
        defaultImage: "0",
        layout: "half_single_column",
        category: "classic",
        isFree: false
    }, {
        code: "stockholm",
        displayName: "mStockholm",
        capabilities: ["bg_pattern"],
        defaultPattern: "5",
        defaultImage: "0",
        layout: "single_column",
        category: "creative",
        isFree: false
    }, {
        code: "pechino",
        displayName: "mPechino",
        capabilities: [],
        defaultPattern: "0",
        defaultImage: "0",
        layout: "half_single_column",
        category: "classic",
        isFree: false
    }, {
        code: "bruxelles",
        displayName: "mBruxelles",
        capabilities: ["photo_format", "name_color"],
        defaultPattern: "0",
        defaultImage: "0",
        layout: "single_column",
        category: "classic",
        isFree: false
    }, {
        code: "dublin",
        displayName: "mDublin",
        capabilities: ["bg_image", "photo_format", "photo_center"],
        defaultPattern: "0",
        defaultImage: "1",
        layout: "half_single_column",
        category: "dynamic",
        isFree: false
    }],

    fontCatalogue: [
        { name: "Alegreya Sans" },
        { name: "Barlow" },
        { name: "Barlow Semi Condensed" },
        { name: "Exo" },
        { name: "Grenze" },
        { name: "K2D" },
        { name: "Kanit" },
        { name: "Libre Franklin" },
        { name: "Livvic" },
        { name: "Montserrat" },
        { name: "Overpass" },
        { name: "Poppins" },
        { name: "Prompt" },
        { name: "Raleway" },
        { name: "Roboto" },
        { name: "Sarabun" },
        { name: "Taviraj" },
        { name: "Tomorrow" },
        { name: "Trirong" },
    ],

    colorCatalogue: [
        { code: "#247BA0" },
        { code: "#17C964" },
        { code: "#F31260" },
        { code: "#F5A524" }
    ],

    basePatternCatalogue: [
        { code: "0" },
        { code: "1" },
        { code: "2" },
        { code: "3" },
        { code: "4" },
        { code: "5" },
        { code: "6" },
        { code: "7" },
        { code: "8" },
        { code: "9" },
        { code: "10" },
        { code: "11" },
        { code: "12" },
    ],

    baseImageCatalogue: [
        { code: "0" },
        { code: "1" },
        { code: "2" },
        { code: "3" },
        { code: "4" },
        { code: "5" },
        { code: "6" },
        { code: "7" },
        { code: "8" },
        { code: "9" },
        { code: "10" },
        { code: "11" },
        { code: "12" },
        { code: "13" },
        { code: "14" },
        { code: "15" },
        { code: "CUSTOM" },
    ],

    abilityFormatCatalogue: [
        { iosIcon: "rectangle", androidIcon: "rectangle-outline", label: "singleBar", code: 'abilityFormatSingleBar' },
        { iosIcon: "circle.dashed", androidIcon: "chart-donut", label: "circular", code: 'abilityFormatCircular' },
        { iosIcon: "circle", androidIcon: "circle-outline", label: "dots", code: 'abilityFormatDots' },
        { iosIcon: "square", androidIcon: "square-outline", label: "squares", code: 'abilityFormatSquares' },
        { iosIcon: "checkmark.square", androidIcon: "checkbox-marked-outline", label: "checks", code: 'abilityFormatChecks' },
        { iosIcon: "minus.square", androidIcon: "minus-box-outline", label: "bars", code: 'abilityFormatBars' },
        { iosIcon: "star", androidIcon: "star-outline", label: "stars", code: 'abilityFormatStars' }
    ],

    cvPickerItems: {
        contactInfoTelephone: () => [
            { label: "notSpecified", code: "" },
            { label: "phoneHome", code: "home" },
            { label: "phoneWork", code: "work" },
            { label: "phoneMobile", code: "mobile" }
        ],
        contactInfoWebsite: () => [
            { label: "notSpecified", code: "" },
            { label: "webPersonal", code: "personal" },
            { label: "webBusiness", code: "business" },
            { label: "webBlog", code: "blog" },
            { label: "webPortfolio", code: "portfolio" }
        ],
        contactInfoInstantMessaging: () => [
            { label: "notSpecified", code: "" },
            { label: "instMessWhatsapp", code: "whatsapp" },
            { label: "instMessTelegram", code: "telegram" },
            { label: "instMessSlack", code: "slack" },
            { label: "instMessGtalk", code: "gtalk" },
            { label: "instMessSkype", code: "skype" }
        ],
        contactInfoSocialAccount: () => [
            { label: "notSpecified", code: "" },
            { label: "socialLinkedin", code: "linkedin" },
            { label: "socialFacebook", code: "facebook" },
            { label: "socialTwitter", code: "twitter" },
            { label: "socialInstagram", code: "instagram" },
            { label: "socialYoutube", code: "youtube" },
            { label: "socialPinterest", code: "pinterest" },
            { label: "socialTiktok", code: "tiktok" }
        ],
        languageProficiencyLevel: () => [
            { label: "notSpecified", code: "" },
            { label: "lngA1", code: "A1" },
            { label: "lngA2", code: "A2" },
            { label: "lngB1", code: "B1" },
            { label: "lngB2", code: "B2" },
            { label: "lngC1", code: "C1" },
            { label: "lngC2", code: "C2" }
        ],
        gender: () => [
            { label: "notSpecified", code: "" },
            { label: "genderOpt1", code: "M" },
            { label: "genderOpt2", code: "F" },
            { label: "genderOpt3", code: "O" },
        ],
        headline: () => [
            { label: "notSpecified", code: "" },
            { label: "headOpt1", code: "job_applied_for" },
            { label: "headOpt2", code: "position" },
            { label: "headOpt3", code: "preferred_job" },
            { label: "headOpt4", code: "studies_applied_for" },
            { label: "headOpt5", code: "personal_statement" }
        ],
        ability: () => [
            { label: "abilityOpt1", code: 0 },
            { label: "abilityOpt2", code: 1 },
            { label: "abilityOpt3", code: 2 },
            { label: "abilityOpt4", code: 3 },
            { label: "abilityOpt5", code: 4 },
            { label: "abilityOpt6", code: 5 }
        ],
        addInfoTitle: () => [
            { label: "notSpecified", code: "" },
            { label: "addInfoOpt1", code: "honors_awards" },
            { label: "addInfoOpt2", code: "publications" },
            { label: "addInfoOpt3", code: "presentations" },
            { label: "addInfoOpt4", code: "projects" },
            { label: "addInfoOpt5", code: "citations" },
            { label: "addInfoOpt6", code: "memberships" },
            { label: "addInfoOpt7", code: "conferences" },
            { label: "addInfoOpt8", code: "seminars" },
            { label: "addInfoOpt9", code: "references" },
            { label: "addInfoOpt10", code: "courses" },
            { label: "addInfoOpt11", code: "certifications" },
            { label: "addInfoOpt12", code: "signature_equivalent" }
        ],
        labelLanguage: () => [
            { code: "ar", label: "أرابيكس" },
            { code: "bg", label: "Български" },
            { code: "es", label: "Español" },
            { code: "cs", label: "Čeština" },
            { code: "da", label: "Dansk" },
            { code: "de", label: "Deutsch" },
            { code: "et", label: "Eesti keel" },
            { code: "el", label: "Ελληνικά" },
            { code: "en", label: "English" },
            { code: "fr", label: "Français" },
            { code: "hr", label: "Hrvatski" },
            { code: "is", label: "Íslenska" },
            { code: "it", label: "Italiano" },
            { code: "lv", label: "Latviešu valoda" },
            { code: "lt", label: "Lietuvių kalba" },
            { code: "hu", label: "Magyar" },
            { code: "mk", label: "македонски" },
            { code: "mt", label: "Malti" },
            { code: "nl", label: "Nederlands" },
            { code: "nb", label: "Norsk" },
            { code: "pl", label: "Polski" },
            { code: "pt", label: "Português" },
            { code: "ro", label: "Română" },
            { code: "sk", label: "Slovenčina" },
            { code: "sl", label: "Slovenščina" },
            { code: "fi", label: "Suomi" },
            { code: "sv", label: "Svenska" },
            { code: "tr", label: "Türkçe" },
            { code: "zh", label: "中国人" },
            { code: "ja", label: "日本語" }
        ],
        viewDate: () => [
            { label: "viewFormatOptDefault", code: "" },
            { label: "viewFormatOpt1", code: "text/short" },
            { label: "viewFormatOpt2", code: "text/long" },
            { label: "viewFormatOpt4", code: "numeric/long" },
            { label: "viewFormatOpt5", code: "numeric/medium" },
            { label: "viewFormatOpt6", code: "numeric/short" },
            { label: "viewFormatOpt7", code: "numeric/long/us" }
        ],
        formatDate: () => [
            { label: "dateFormatOpt1", code: "" },
            { label: "dateFormatOpt2", code: "MONTH_YEAR" },
            { label: "dateFormatOpt3", code: "YEAR" }
        ],
        photoFormat: () => [
            { code: "default" },
            { code: "square" },
            { code: "rounded" },
            { code: "drop_br" },
            { code: "drop_bl" },
            { code: "drop_tr" },
            { code: "drop_tl" },
            { code: "drop_tl_br" },
            { code: "drop_tr_bl" },
            { code: "drop_tr_tl" },
            { code: "drop_br_tr" },
            { code: "drop_br_bl" },
            { code: "drop_tl_bl" }
        ],
        pdfSize: () => [
            { label: "standard", code: "STANDARD" },
            { label: "compressed", code: "COMPRESSED" },
            { label: "minimum", code: "MINUMUN" }
        ],
    },

    prewrittenPhraseCategoryCatalogue: {
        ACC: 19, ACH: 13, ADM: 30, ASK: 9, APP: 15, CSK: 48, GSK: 26, DSK: 13, CMA: 20,
        CRE: 37, DMA: 29, DEL: 24, DEP: 24, DEV: 50, ESK: 38, GOB: 39, IMP: 34, INI: 26, JUD: 27, LEA: 30, LSK: 13,
        LOY: 12, MSK: 66, MAT: 13, MOT: 57, OSK: 21, PLA: 41, POT: 26, PQU: 25, GQU: 59, PRO: 10, QUL: 14, QUT: 14,
        RWO: 55, REO: 11, REP: 16, STR: 41, SSK: 102, TDI: 27, VES: 12, TSK: 31, WSK: 17, MEN: 39, OEX: 43
    },

    sectionGroupedCatalogue: [{
        key: "cvBase",
        items: [
            { metadataKey: "ContactInfo", defaultLabelKey: "PersonalInfo", },
            { metadataKey: "Summary", defaultLabelKey: "Summary", },
            { metadataKey: "WorkExperience", defaultLabelKey: "Experience", },
            { metadataKey: "Education", defaultLabelKey: "Education", },
            { metadataKey: "Skills", defaultLabelKey: "Skills", },
        ]
    },
    {
        key: "driverLicences",
        items: [{ metadataKey: "Driving", defaultLabelKey: "DriverLicence" }, { metadataKey: "DrivingSpecial", defaultLabelKey: "SpecialDriverLicence" }]
    },
    {
        key: "languages",
        items: [{ metadataKey: "MotherTongue", defaultLabelKey: "MotherTongue" }, { metadataKey: "ForeignLanguage", defaultLabelKey: "ForeignLanguage" }],
    },
    {
        key: "cvAdvanced",
        items: [
            { metadataKey: "ExpertiseArea", defaultLabelKey: "Keyword" },
            { metadataKey: "Video", defaultLabelKey: "Video" },
            { metadataKey: "Ability", defaultLabelKey: "Ability" },
            { metadataKey: "Portfolio", defaultLabelKey: "Portfolio" },
            { metadataKey: "Achievement", defaultLabelKey: "Achievement" },
            { metadataKey: "Attachment", defaultLabelKey: "Attachment" },
        ]
    }],

}