
module.exports = {
	getCommonCss: cv => {

		const meta = cv.Metadata;
		const fixFont = ["zh", "ja"].includes(cv.Metadata.lng) ? "Noto Sans SC" : null // non trad language
		const isRtl = cv.Metadata.lng === "ar"
		const direction = isRtl ? "rtl" : "ltr"

		const baseColor = meta.modelBaseColor || '#247BA0',
			baseFont = fixFont || meta.modelBaseFont || 'Montserrat',
			baseFontSize = meta.modelBaseFontSize || 11,
			titleColor = meta.modelTitleColor || baseColor,
			titleFont = fixFont || meta.modelTitleFont || baseFont,
			titlesInUppercase = meta.titlesInUppercase || false,
			titlesInUnderline = meta.titlesInUnderline || false,
			nameFont = fixFont || meta.modelNameFont || baseFont,
			nameFontSize = meta.modelNameFontSize || 48,
			nameColor = meta.modelNameColor || 'black',

			workTypeColor = meta.workTypeColor || 'black',
			workTypeFont = fixFont || meta.workTypeFont || baseFont || 'Montserrat',
			workTypeInUppercase = meta.workTypeInUppercase || false,
			workTypeInBold = meta.workTypeInBold === false ? false : true,
			workTypeInItalic = meta.workTypeInItalic || false,
			workTypeInUnderline = meta.workTypeInUnderline || false,

			companyFont = fixFont || meta.companyFont || baseFont || 'Montserrat',
			companyColor = meta.companyColor || 'black',
			companyInBold = meta.companyInBold || false,
			companyInItalic = (meta.companyInItalic === false) ? false : true,
			companyInUnderline = meta.companyInUnderline || false,
			companyInUppercase = meta.companyInUppercase || false,

			qualificationFont = fixFont || meta.qualificationFont || baseFont || 'Montserrat',
			qualificationColor = meta.qualificationColor || 'black',
			qualificationInBold = meta.qualificationInBold === false ? false : true,
			qualificationInItalic = meta.qualificationInItalic || false,
			qualificationInUnderline = meta.qualificationInUnderline || false,
			qualificationInUppercase = meta.qualificationInUppercase || false,

			instituteFont = fixFont || meta.instituteFont || baseFont || 'Montserrat',
			instituteColor = meta.instituteColor || 'black',
			instituteInBold = meta.instituteInBold || false,
			instituteInItalic = meta.instituteInItalic === false ? false : true,
			instituteInUnderline = meta.instituteInUnderline || false,
			instituteInUppercase = meta.instituteInUppercase || false,

			skillFont = fixFont || meta.skillFont || baseFont || 'Montserrat',
			skillColor = meta.skillColor || 'black',
			skillInBold = meta.skillInBold === false ? false : true,
			skillInItalic = meta.skillInItalic || false,
			skillInUnderline = meta.skillInUnderline || false,
			skillInUppercase = meta.skillInUppercase || false,

			addInfoFont = fixFont || meta.addInfoFont || baseFont || 'Montserrat',
			addInfoColor = meta.addInfoColor || 'black',
			addInfoInBold = meta.addInfoInBold === false ? false : true,
			addInfoInItalic = meta.addInfoInItalic || false,
			addInfoInUnderline = meta.addInfoInUnderline || false,
			addInfoInUppercase = meta.addInfoInUppercase || false,

			signatureOnLeft = meta.signatureOnLeft || false,
			exAreaColor = meta.modelExAreaColor || baseColor,
			bgExAreaFormat = meta.modelBgExAreaFormat || 5,
			abilityColor = meta.modelAbilityColor || baseColor,
			hobbyColor = meta.modelHobbyColor || baseColor,

			baseImageData = meta.modelBaseImageData


		return `
		/* ----------------------------------------------- DOCUMENT */
		.page {
			font-family: ${baseFont}, sans-serif;
			font-size: ${baseFontSize}px;
			color: black !important;
			letter-spacing: .3px;
			background-color: white;
			width: 760px;
			min-height: 710px;
			margin: 0 auto;
			box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
			direction: ${direction};
		}
		.body {
			display: table;
		}
		.left-column {
			width: 220px;
			max-width: 240px;
			min-width: 240px;
			${isRtl ? "padding-right: 40px" : "padding-left: 40px"}; 
			display: table-cell;
			padding-top: 20px;
			vertical-align: top;
		}
		.right-column {
			width: 530px;
			max-width: 530px;
			padding-left: ${isRtl ? "40" : "20"}px; 
			padding-right: ${isRtl ? "20" : "40"}px; 
			padding-top: 20px;
			display: table-cell;
		}
		.half-column {
			width: 400px;
			max-width: 400px;
			padding: 20px 40px 0px 40px;
			display: table-cell;
		}
		.half-column:nth-child(1) {
		padding-right: 20px;
		}
		.half-column:nth-child(2) {
		padding-left: 20px;
		}
		.unique-column {
			display: table;
			padding-left: 40px;
			padding-right: 40px;
			width: 89%;
		}
		.content{
			margin-bottom: 10px;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			text-transform: none;
			margin: 0;
			word-break: break-word;
		}

		a {
			color: inherit;
			text-decoration: inherit;
		}

		@media print {
			body {
				margin: 0;
			}
			.body {
				margin: 0;
			}
			.page {
				width: 100%;
				height: 100%;
				margin: 0;
				box-shadow: none;
			}
		}

		@page {
			size: A4;
		}

		@page:first { margin-top: 0; margin-bottom: 100px }
		/* ----------------------------------------------- NAME */
		.ptXS {
			padding-top: 5px;
		}

		/* ----------------------------------------------- NAME */
		.name-container{
			display: table-cell;
			vertical-align: middle;
			width: 100%;
			background-color: white;
		}
		.name {
			color: ${nameColor};
			font-weight: bold;
		}
		.name-with-pro-title {
			margin-bottom: 10px;
		}
		.name, .surname{
			font-family: ${nameFont}, sans-serif;
			font-size: ${nameFontSize}px !important;
		}
		.professional-title{
			color: ${nameColor};
			font-family: ${nameFont}, sans-serif;
			font-size: ${parseInt(nameFontSize) - 20}px !important;
		}
		/* ----------------------------------------------- HEADER */
		.header{
			padding: 20px 40px 0px 40px;
		}
		.header-top{
			width: 100%;
			background-color: ${baseColor};
			height: 40px;
		}

		.bg-image-CUSTOM{
			background-image: url(data:image/png;base64,${baseImageData})
		}

		/* ----------------------------------------------- PROFILE PIC */
		.profile-pic-container{
			display: table-cell;
			vertical-align: middle;
			background-color: white;
		}
		.profile-pic {
			border: 3px solid ${baseColor};
			width: 140px;
			height: 140px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			background-color: #F2F2F1;
			border-radius: 75px;
			margin: 0px auto;
		}
		.pic-format-square {
			border-radius: 0 !important;
		}
		.pic-format-rounded {
			border-radius: 25px !important;
		}
		.pic-format-drop_br {
			border-radius: 0 !important;
			border-bottom-left-radius: 75px !important;
			border-top-right-radius: 75px !important;
			border-top-left-radius: 75px !important;
		}
		.pic-format-drop_bl {
			border-radius: 0 !important;
			border-bottom-right-radius: 75px !important;
			border-top-right-radius: 75px !important;
			border-top-left-radius: 75px !important;

		}
		.pic-format-drop_tr {
			border-radius: 0 !important;
			border-bottom-right-radius: 75px !important;
			border-bottom-left-radius: 75px !important;
			border-top-left-radius: 75px !important;
		}
		.pic-format-drop_tl {
			border-radius: 0 !important;
			border-bottom-right-radius: 75px !important;
			border-bottom-left-radius: 75px !important;
			border-top-right-radius: 75px !important;
		}
		.pic-format-drop_tl_br {
			border-radius: 0 !important;
			border-bottom-left-radius: 75px !important;
			border-top-right-radius: 75px !important;
		}
		.pic-format-drop_tr_bl {
			border-radius: 0 !important;
			border-bottom-right-radius: 75px !important;
			border-top-left-radius: 75px !important;
		}
		.pic-format-drop_tr_tl {
			border-radius: 0 !important;
			border-top-right-radius: 75px !important;
			border-top-left-radius: 75px !important;
		}
		.pic-format-drop_br_tr {
			border-radius: 0 !important;
			border-bottom-right-radius: 75px !important;
			border-top-right-radius: 75px !important;
		}
		.pic-format-drop_br_bl {
			border-radius: 0 !important;
			border-bottom-right-radius: 75px !important;
			border-bottom-left-radius: 75px !important;
		}
		.pic-format-drop_tl_bl {
			border-radius: 0 !important;
			border-top-left-radius: 75px !important;
			border-bottom-left-radius: 75px !important;
		}

		/* ----------------------------------------------- CONTACT INFO */
		.contact-info-tag-container{
			display: table;
		}
		.contact-info-tag-text{
			vertical-align: middle;
			word-break: break-word;
		}
		.contact-info-tag-icon{
			padding-right: 5px;
			display: table-cell;
			vertical-align: middle;
			width: 20px;
			color: ${baseColor}
		}
		/* ----------------------------------------------- MOTHER LANGUAGES */
		.mothertongue-content {
			text-transform: capitalize;
			font-weight: bold;
		}
		/* ----------------------------------------------- FOREIGN LANGUAGES */
		.foreign-lng-table {
			width: 100%;
			text-align: left;
		}
		.foreign-lng-content>.section-subtitle-container>p {
			text-transform: capitalize;
		}
		.foreign-lng-th {
			font-weight: normal;
		}
		.foreign-lng-td {
			font-style: italic;
		}
		.certificate-label{
			text-decoration: underline;
		}
		/* ----------------------------------------------- EXPERTISE AREA */
		.expertise-area-text {
			background-color: ${exAreaColor};
			border-radius: ${bgExAreaFormat}px;
			display: inline-block;
			color: white;
			margin: 1px;
			margin-bottom: 2px;
			padding: 5px;
			font-weight: bold;
		}

		/* ----------------------------------------------- HOBBY */

		.left-column > * .hobby-item{
			display: table;
			width: 100%;
		}

		.right-column > * .hobby-item{
			display: inline-table;
			width: 32%;
		}

		.half-column > * .hobby-item {
			display: inline-table;
			width: 49%;
		}
		.unique-column > * .hobby-item {
			display: inline-table;
			width: 32%;
		}

		.hobby-item {
			margin-bottom: 5px;
			page-break-inside: avoid;
		}

		.hobby-title {
			display: table-cell;
			vertical-align: middle;
			font-weight: bold;
			word-break: break-word;
		}

		.left-column > * .hobby-icon-container{
			width: 25%;
		}

		.right-column > * .hobby-icon-container{
			width: 30%;
		}

		.hobby-icon-container {
			display: table-cell;
			vertical-align: middle;
			font-weight: bold;
			word-break: break-word;
			width: 25%;
			font-size: 25px;
		}

		.hobby-icon{
			color: ${hobbyColor};
		}

		/* ----------------------------------------------- PORTFOLIO */

		.left-column > * .portfolio-item{
			height: 115px;
			width: 115px;
		}
		.right-column > * .portfolio-item {
			height: 135px;
			width: 135px;
		}
		.half-column > * .portfolio-item {
			height: 156px;
			width: 156px;
		}
		.unique-column > * .portfolio-item {
			height: 100px;
			width: 100px;
		}
		.portfolio-item {
			height: 160px;
			width: 160px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			display: inline-block;
		}

		/* ----------------------------------------------- ATTACHMENT */
		.attachment-file-name {
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: center;
			width: 100px;
			margin-top: 0px;
			margin-bottom: -5px;
		}

		.left-column > * .attachment-item{
			height: 115px;
			width: 115px;
		}
		.right-column > * .attachment-item {
			height: 100px;
			width: 100px;
		}
		.half-column > * .attachment-item {
			height: 156px;
			width: 156px;
		}
		.half-column > * .attachment-file-name {
			width: 150px;
		}

		.unique-column > * .attachment-item {
			height: 150px;
			width: 150px;
		}
		.unique-column > * .attachment-file-name {
			width: 150px;
		}

		.attachment-item {
			height: 160px;
			width: 160px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			display: inline-block;
		}


		/* ----------------------------------------------- ABILITY */
		.ability-circle {
		--v:calc( ((18/5) * var(--p) - 90)*1deg);
		width:50px;
		height:50px;
		display:inline-block;
		border-radius:50%;
		padding:10px;
		background:
			linear-gradient(white,white) content-box,
			linear-gradient(var(--v), #e9e9e9     50%,transparent 0) 0/min(100%,(50 - var(--p))*100%),
			linear-gradient(var(--v), transparent 50%,${abilityColor} 0) 0/min(100%,(var(--p) - 50)*100%),
			linear-gradient(to right, #e9e9e9 50%,${abilityColor} 0);
		}

		circle.fg {
			transform: rotate(-90deg);
			transform-origin: 25px 25px;
		  }

		.pie,
		.c100 .bar,
		.c100.p60 .fill,
		.c100.p80 .fill,
		.c100.p100 .fill {
		border: 0.08em solid ${abilityColor};
		}

		.rect-auto,
		.c100.p60 .slice,
		.c100.p80 .slice,
		.c100.p100 .slice {
			clip: rect(auto, auto, auto, auto);
		}

		.pie,
		.c100 .bar,
		.c100.p60 .fill,
		.c100.p80 .fill,
		.c100.p100 .fill {
			position: absolute;
			width: 0.84em;
			height: 0.84em;
			clip: rect(0em, 0.5em, 1em, 0em);
			border-radius: 50%;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		.pie-fill,
		.c100.p60 .bar:after,
		.c100.p60 .fill,
		.c100.p80 .bar:after,
		.c100.p80 .fill,
		.c100.p100 .bar:after,
		.c100.p100 .fill {
			-webkit-transform: rotate(180deg);
			-moz-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			transform: rotate(180deg);
		}

		.c100 {
			position: relative;
			font-size: 120px;
			width: 1em;
			height: 1em;
			border-radius: 50%;
			float: left;
			margin: 0 0.1em 0.1em 0;
			background-color: #cccccc;
		}

		.c100 *,
		.c100 *:before,
		.c100 *:after {
			-webkit-box-sizing: content-box;
			-moz-box-sizing: content-box;
			box-sizing: content-box;
		}

		.c100.center {
			float: none;
			margin: 0 auto;
		}

		.c100.big {
			font-size: 240px;
		}

		.c100.small {
			font-size: 70px;
			padding: 2px;
		}

		.c100:after {
			position: absolute;
			top: 0.14em;
			left: 0.15em;
			display: block;
			content: " ";
			border-radius: 50%;
			background-color: #f5f5f5;
			width: 0.77em;
			height: 0.77em;
			-webkit-transition-property: all;
			-moz-transition-property: all;
			-o-transition-property: all;
			transition-property: all;
			-webkit-transition-duration: 0.2s;
			-moz-transition-duration: 0.2s;
			-o-transition-duration: 0.2s;
			transition-duration: 0.2s;
			-webkit-transition-timing-function: ease-in;
			-moz-transition-timing-function: ease-in;
			-o-transition-timing-function: ease-in;
			transition-timing-function: ease-in;
		}

		.c100 .slice {
			position: absolute;
			width: 1em;
			height: 1em;
			clip: rect(0em, 1em, 1em, 0.5em);
		}

		.c100.p20 .bar {
			-webkit-transform: rotate(72deg);
			-moz-transform: rotate(72deg);
			-ms-transform: rotate(72deg);
			-o-transform: rotate(72deg);
			transform: rotate(72deg);
		}

		.c100.p40 .bar {
			-webkit-transform: rotate(144deg);
			-moz-transform: rotate(144deg);
			-ms-transform: rotate(144deg);
			-o-transform: rotate(144deg);
			transform: rotate(144deg);
		}

		.c100.p60 .bar {
			-webkit-transform: rotate(216deg);
			-moz-transform: rotate(216deg);
			-ms-transform: rotate(216deg);
			-o-transform: rotate(216deg);
			transform: rotate(216deg);
		}

		.c100.p80 .bar {
			-webkit-transform: rotate(288deg);
			-moz-transform: rotate(288deg);
			-ms-transform: rotate(288deg);
			-o-transform: rotate(288deg);
			transform: rotate(288deg);
		}

		.c100.p100 .bar {
			-webkit-transform: rotate(360deg);
			-moz-transform: rotate(360deg);
			-ms-transform: rotate(360deg);
			-o-transform: rotate(360deg);
			transform: rotate(360deg);
		}

		.ability-item {
			display: table;
			width: 100%;
			margin-bottom: 5px;
			page-break-inside: avoid;
		}

		.ability-title {
			display: table-cell;
			vertical-align: middle;
			font-weight: bold;
			word-break: break-word;
		}

		.ability-level-container {
			display: table-cell;
			text-align: ${isRtl ? "left" : "right"};
			white-space: nowrap;
			vertical-align: middle;
			padding-left: 15px;
		}

		.ability-level-container > i {
			color: ${abilityColor};
			background-color: white;
			font-size: 15px;
		}

		.ability-level-single-bar {
			width: 50%;
		}
		.ability-level-single-bar-background {
			width: 100%;
			background-color: #e9e9e9;
			border: 3px solid #e9e9e9;
			border-radius: 10px;
		}
		.ability-level-single-bar-content {
			background-color: ${abilityColor};
			position: relative;
			height: 10px;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}

		.ability-level-bars {
			text-align: right;
			display: table-cell;
			white-space: nowrap;
			vertical-align: middle;
			padding-left: 15px;
		}
		.ability-level-bars-background {
			width: 20px;
			background-color: #e9e9e9;
			display: inline-block;
			border: 3px solid #e9e9e9;
			border-radius: 15px;
		}
		.ability-level-bars-content{
			background-color: ${abilityColor};
			position: relative;
			height: 5px;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}

		.ability-level-0 { width: 0%; }
		.ability-level-1 { width: 20%; }
		.ability-level-2 { width: 40%; }
		.ability-level-3 { width: 60%; }
		.ability-level-4 { width: 80%; }
		.ability-level-5 {
			width: 100%;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}

		.left-column>.container>.ability-circle-container-all {
			text-align: center;
		}

		.ability-circle-container {
			display: inline-block;
			width: 70px;
			padding: 10px;
			word-break: break-word;
			vertical-align: text-top;
			text-align: center;
		}

		.ability-circle-container>* {
			vertical-align: middle;
		}

		.ability-circle-title {
			margin-top: 5px;
			text-align: center;
			margin-left: -5px;
			margin-right: -5px;
			font-weight: bold;
		}
		/* ----------------------------------------------- VIDEO */
		.left-column > * .video-item {
			padding: 5px;
			background-color: white;
			margin-top: 5px;
		}
		/* ----------------------------------------------- SIGNATURE */
		.signature-pic{
			float: ${signatureOnLeft ? "left" : "right"};
		}
		.signature-pic-container {
			padding-left: 50px;
			padding-right: 50px;
			page-break-inside: avoid
		}
		.signature-pic {
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			transform: rotate(90deg);
		}
		.signature-small {
			margin-top: -25px;
			margin-left: -25px;
			margin-right: -25px;
			height: 100px;
			width: 50px;
			margin-bottom: -20px;
		}
		.signature-standard {
			margin-top: -50px;
			margin-bottom: -50px;
			height: 200px;
			width: 100px;
		}
		.signature-large {
			margin-top: -75px;
			margin-left: 25px;
			margin-right: 25px;
			margin-bottom: -75px;
			height: 300px;
			width: 150px;
		}

		/* ----------------------------------------------- TITLES  */
		.section-title{
			font-family: ${titleFont}, sans-serif;
			text-transform: ${titlesInUppercase ? "uppercase" : "initial"};
			text-decoration: ${titlesInUnderline ? "underline" : "unset"};
			color: ${titleColor};
			font-weight: bold;
			font-size: ${parseInt(baseFontSize) + 1}px;
		}
		.section-title-icon{
			display: none;
		}
		.section-title-container {
			display: table;
		}

		/* ----------------------------------------------- WORK  */
		.work-company{
			display: table;
			width: 100%;
		}
		.work-company-name{
			display: table-cell;
		} 
		.work-company-period{
			padding-left: 20px;
			display: table-cell;
			white-space: nowrap;
		}
		.employer-name, .employer-address{
			font-family: ${companyFont}, sans-serif;
			color: ${companyColor};
			text-transform: ${companyInUppercase ? "uppercase" : "initial"};
			text-decoration: ${companyInUnderline ? "underline" : "unset"};
			font-weight: ${companyInBold ? "bold" : "400"};
			font-style: ${companyInItalic ? "italic" : "normal"} !important;
		}
		.employer-sector-label{
			text-decoration: underline;
		}
		/* ----------------------------------------------- EDUCATION  */
		.education-institute{
			display: table;
			width: 100%;
		}
		.education-instatitute-name{
			display: table-cell;
		} 
		.education-instatitute-period{
			padding-left: 20px;
			display: table-cell;
			white-space: nowrap;
		}
		.organisation-name, .organisation-address{
			font-family: ${instituteFont}, sans-serif;
			color: ${instituteColor};
			text-transform: ${instituteInUppercase ? "uppercase" : "initial"};
			text-decoration: ${instituteInUnderline ? "underline" : "unset"};
			font-weight: ${instituteInBold ? "bold" : "400"};
			font-style: ${instituteInItalic ? "italic" : "normal"} !important;
		}
		/* ----------------------------------------------- LOGO DESCRITPIONS */
		.section-with-logo{
			display: table;
		}
		.section-with-logo > .section-description-container{
			display: table-cell;
			vertical-align: middle;
			width: 90%;
			padding-right: 20px;
		}
		.logo-container{
			display: table-cell;
			vertical-align: middle;
		}
		.logo{
			width: 75px;
			height: 75px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
		}
		/* ----------------------------------------------- CUSTOM DESCRITPIONS */
		.section-description-container {
			word-break: break-word;
			padding-top: 5px;
		}
		.section-description-container *, .section-description-container > * {
			font-size: ${baseFontSize}px !important;
		}
		.section-period-content{
			font-style: italic;
			text-align: right;
		}
		/*  ----------------------------------------------- SUBTITLES */
		.section-subtitle{
			font-weight: bold;
		}
		.section-subtitle-container{
			padding-top: 5px;
		}
		.section-subtitle-position{
			font-family: ${workTypeFont}, sans-serif;
			color: ${workTypeColor};
			text-transform: ${workTypeInUppercase ? "uppercase" : "initial"};
			text-decoration: ${workTypeInUnderline ? "underline" : "unset"};
			font-weight: ${workTypeInBold ? "bold" : "400"} !important;
			font-style: ${workTypeInItalic ? "italic" : "normal"};
		}
		.section-subtitle-education{
			font-family: ${qualificationFont}, sans-serif;
			color: ${qualificationColor};
			text-transform: ${qualificationInUppercase ? "uppercase" : "initial"};
			text-decoration: ${qualificationInUnderline ? "underline" : "unset"};
			font-weight: ${qualificationInBold ? "bold" : "400"} !important;
			font-style: ${qualificationInItalic ? "italic" : "normal"};
		}

		.section-subtitle-skill{
			font-family: ${skillFont}, sans-serif;
			color: ${skillColor};
			text-transform: ${skillInUppercase ? "uppercase" : "initial"};
			text-decoration: ${skillInUnderline ? "underline" : "unset"};
			font-weight: ${skillInBold ? "bold" : "400"} !important;
			font-style: ${skillInItalic ? "italic" : "normal"};
		}
		.section-subtitle-addInfo{
			font-family: ${addInfoFont}, sans-serif;
			color: ${addInfoColor};
			text-transform: ${addInfoInUppercase ? "uppercase" : "initial"};
			text-decoration: ${addInfoInUnderline ? "underline" : "unset"};
			font-weight: ${addInfoInBold ? "bold" : "400"} !important;
			font-style: ${addInfoInItalic ? "italic" : "normal"};
		}
	`
	}
}